export class LotoNumber {
        public id:number;
        public data: Array<number> = [];

        public interval: number = 0;
        public  maxDist: number = 0;
        public  minDist: number = 10000;
        public willAppearIn: number = -1;

        constructor(public name: string) {
            this.id = Number(name);
        }

        public addValue(value:number):void{
            this.data.push(value);
        }

        computeInterval(): void {
	        for (let i = 0; i < this.data.length-1; i++) {
	            if((this.data[i+1] - this.data[i] < this.minDist)){
	                this.minDist = this.data[i+1] - this.data[i];
                }
		        if((this.data[i+1] - this.data[i] > this.maxDist)){
			        this.maxDist = this.data[i+1] - this.data[i];
		        }
	        }
	        this.interval = this.maxDist;
	        this.willAppearIn = this.maxDist - (this.data[this.data.length-1] - this.data[this.data.length-2]);
        }
}
