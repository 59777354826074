import { Component, OnInit } from '@angular/core';
import {LotoHistoryItem} from './LotoHistoryItem';
import {HistoryService} from './history.service';
import {GenerateNumbersService} from './generate-numbers.service';

@Component({
  selector: 'app-loto',
  templateUrl: './loto.component.html',
  styleUrls: ['./loto.component.css']
})
export class LotoComponent implements OnInit {
  data: LotoHistoryItem[];

  validatedNumbers: number[];
  constructor(private historyService: HistoryService, private generateNumbersServie: GenerateNumbersService) { }

  ngOnInit() {
    this.historyService.getHistory()
        .subscribe(data => this.data = data);
  }

  generateNumbers():void{
    this.generateNumbersServie.getNumbers()
        .subscribe(validatedNumbers => this.validatedNumbers = validatedNumbers);
  }

}
