import {LotoHistoryItem} from './LotoHistoryItem';

export const LOTO_DATA: LotoHistoryItem[] = [
  {date: "1993-08-08", numbers: [38, 17, 47, 25, 30, 37]},
  {date: "1993-08-15", numbers: [6, 7, 30, 17, 2, 42]},
  {date: "1993-08-22", numbers: [44, 20, 17, 3, 38, 45]},
  {date: "1993-08-29", numbers: [42, 30, 36, 5, 4, 6]},
  {date: "1993-09-05", numbers: [5, 14, 7, 49, 35, 19]},
  {date: "1993-09-12", numbers: [42, 28, 41, 15, 22, 38]},
  {date: "1993-09-19", numbers: [18, 4, 16, 35, 2, 17]},
  {date: "1993-10-03", numbers: [44, 48, 34, 37, 4, 41]},
  {date: "1993-10-17", numbers: [13, 7, 6, 45, 29, 25]},
  {date: "1993-10-24", numbers: [8, 22, 43, 14, 34, 15]},
  {date: "1993-10-31", numbers: [14, 17, 47, 19, 36, 12]},
  {date: "1993-11-14", numbers: [48, 20, 6, 43, 4, 35]},
  {date: "1993-11-28", numbers: [37, 36, 17, 35, 15, 8]},
  {date: "1993-12-12", numbers: [12, 33, 41, 7, 34, 43]},
  {date: "1993-12-19", numbers: [49, 15, 46, 25, 12, 32]},
  {date: "1994-01-16", numbers: [7, 20, 27, 46, 2, 33]},
  {date: "1994-01-23", numbers: [39, 5, 6, 19, 20, 2]},
  {date: "1994-01-30", numbers: [14, 47, 40, 26, 24, 36]},
  {date: "1994-02-06", numbers: [45, 10, 18, 43, 35, 11]},
  {date: "1994-02-13", numbers: [38, 29, 49, 47, 8, 3]},
  {date: "1994-02-20", numbers: [16, 42, 33, 4, 38, 7]},
  {date: "1994-03-13", numbers: [22, 39, 35, 29, 44, 46]},
  {date: "1994-03-20", numbers: [34, 9, 10, 19, 2, 47]},
  {date: "1994-03-27", numbers: [11, 8, 25, 13, 3, 38]},
  {date: "1994-04-02", numbers: [38, 49, 36, 20, 19, 5]},
  {date: "1994-04-10", numbers: [49, 42, 11, 45, 9, 23]},
  {date: "1994-04-17", numbers: [23, 46, 1, 35, 2, 22]},
  {date: "1994-04-24", numbers: [10, 41, 29, 25, 18, 8]},
  {date: "1994-05-01", numbers: [19, 7, 16, 18, 6, 49]},
  {date: "1994-05-08", numbers: [15, 44, 32, 21, 49, 10]},
  {date: "1994-05-15", numbers: [29, 32, 38, 24, 11, 15]},
  {date: "1994-05-22", numbers: [10, 18, 23, 40, 35, 33]},
  {date: "1994-05-29", numbers: [37, 10, 44, 27, 47, 48]},
  {date: "1994-06-05", numbers: [34, 7, 49, 14, 47, 44]},
  {date: "1994-06-12", numbers: [43, 49, 4, 36, 24, 26]},
  {date: "1994-06-19", numbers: [46, 27, 6, 31, 2, 11]},
  {date: "1994-06-26", numbers: [27, 14, 3, 43, 22, 42]},
  {date: "1994-07-03", numbers: [47, 38, 36, 19, 5, 35]},
  {date: "1994-07-10", numbers: [41, 4, 39, 13, 24, 33]},
  {date: "1994-07-17", numbers: [18, 29, 17, 48, 47, 34]},
  {date: "1994-07-24", numbers: [33, 29, 31, 23, 41, 6]},
  {date: "1994-07-31", numbers: [29, 17, 11, 14, 28, 39]},
  {date: "1994-08-07", numbers: [35, 16, 8, 47, 46, 36]},
  {date: "1994-08-14", numbers: [36, 21, 5, 47, 40, 33]},
  {date: "1994-08-21", numbers: [25, 31, 46, 39, 1, 22]},
  {date: "1994-08-28", numbers: [20, 45, 32, 23, 18, 19]},
  {date: "1994-09-04", numbers: [29, 27, 44, 43, 30, 46]},
  {date: "1994-09-11", numbers: [28, 26, 48, 37, 44, 1]},
  {date: "1994-09-18", numbers: [1, 15, 9, 45, 44, 14]},
  {date: "1994-09-25", numbers: [41, 7, 48, 3, 11, 16]},
  {date: "1994-10-02", numbers: [5, 8, 48, 2, 28, 25]},
  {date: "1994-10-09", numbers: [49, 39, 9, 42, 37, 13]},
  {date: "1994-10-16", numbers: [10, 7, 45, 2, 8, 20]},
  {date: "1994-10-23", numbers: [48, 38, 15, 45, 12, 42]},
  {date: "1994-10-30", numbers: [4, 33, 17, 45, 13, 39]},
  {date: "1994-11-06", numbers: [16, 11, 12, 14, 15, 38]},
  {date: "1994-11-13", numbers: [28, 45, 2, 7, 19, 43]},
  {date: "1994-11-20", numbers: [25, 23, 41, 40, 27, 34]},
  {date: "1994-11-27", numbers: [18, 44, 1, 43, 40, 9]},
  {date: "1994-12-04", numbers: [3, 31, 1, 28, 15, 12]},
  {date: "1994-12-11", numbers: [38, 13, 31, 28, 19, 23]},
  {date: "1994-12-18", numbers: [49, 31, 10, 43, 37, 12]},
  {date: "1995-01-02", numbers: [25, 18, 42, 36, 43, 12]},
  {date: "1995-01-15", numbers: [20, 44, 18, 48, 19, 28]},
  {date: "1995-01-22", numbers: [41, 8, 37, 22, 6, 5]},
  {date: "1995-01-29", numbers: [41, 36, 18, 23, 27, 10]},
  {date: "1995-02-05", numbers: [38, 12, 1, 2, 44, 41]},
  {date: "1995-02-12", numbers: [1, 31, 16, 4, 33, 18]},
  {date: "1995-02-19", numbers: [23, 5, 42, 18, 15, 12]},
  {date: "1995-03-01", numbers: [12, 49, 23, 18, 5, 28]},
  {date: "1995-03-05", numbers: [39, 46, 35, 43, 17, 16]},
  {date: "1995-03-12", numbers: [36, 37, 24, 48, 10, 5]},
  {date: "1995-03-19", numbers: [13, 25, 16, 14, 49, 37]},
  {date: "1995-03-26", numbers: [4, 35, 39, 22, 11, 46]},
  {date: "1995-04-02", numbers: [19, 5, 24, 9, 36, 40]},
  {date: "1995-04-09", numbers: [3, 22, 13, 5, 35, 6]},
  {date: "1995-04-16", numbers: [16, 30, 11, 4, 19, 46]},
  {date: "1995-04-23", numbers: [34, 28, 48, 32, 4, 20]},
  {date: "1995-04-30", numbers: [30, 46, 39, 19, 35, 48]},
  {date: "1995-05-07", numbers: [36, 4, 12, 20, 7, 39]},
  {date: "1995-05-14", numbers: [32, 14, 44, 34, 21, 24]},
  {date: "1995-05-21", numbers: [29, 43, 24, 8, 17, 18]},
  {date: "1995-05-28", numbers: [13, 33, 16, 1, 30, 36]},
  {date: "1995-06-04", numbers: [42, 35, 5, 26, 37, 25]},
  {date: "1995-06-11", numbers: [36, 15, 43, 19, 16, 32]},
  {date: "1995-06-18", numbers: [38, 34, 9, 24, 25, 36]},
  {date: "1995-06-25", numbers: [20, 30, 48, 29, 16, 36]},
  {date: "1995-07-02", numbers: [43, 47, 34, 4, 8, 48]},
  {date: "1995-07-09", numbers: [45, 28, 43, 40, 32, 29]},
  {date: "1995-07-16", numbers: [31, 26, 16, 10, 23, 43]},
  {date: "1995-07-23", numbers: [27, 31, 24, 40, 7, 19]},
  {date: "1995-07-30", numbers: [32, 25, 21, 6, 27, 14]},
  {date: "1995-08-06", numbers: [16, 9, 46, 41, 1, 37]},
  {date: "1995-08-13", numbers: [24, 33, 25, 44, 42, 28]},
  {date: "1995-08-20", numbers: [28, 2, 30, 16, 29, 10]},
  {date: "1995-08-27", numbers: [45, 40, 16, 6, 32, 38]},
  {date: "1995-09-03", numbers: [28, 35, 41, 34, 20, 25]},
  {date: "1995-09-10", numbers: [10, 17, 28, 32, 27, 35]},
  {date: "1995-09-17", numbers: [22, 6, 19, 3, 31, 15]},
  {date: "1995-09-24", numbers: [31, 7, 9, 40, 26, 14]},
  {date: "1995-10-01", numbers: [13, 6, 21, 41, 19, 15]},
  {date: "1995-10-08", numbers: [45, 28, 2, 34, 26, 7]},
  {date: "1995-10-15", numbers: [39, 3, 26, 38, 11, 20]},
  {date: "1995-10-22", numbers: [28, 2, 43, 47, 12, 19]},
  {date: "1995-10-29", numbers: [8, 42, 17, 12, 32, 43]},
  {date: "1995-11-05", numbers: [10, 12, 36, 42, 18, 1]},
  {date: "1995-11-12", numbers: [21, 18, 15, 35, 11, 7]},
  {date: "1995-11-19", numbers: [21, 1, 9, 16, 37, 42]},
  {date: "1995-11-26", numbers: [18, 45, 17, 23, 9, 39]},
  {date: "1995-12-03", numbers: [39, 46, 40, 41, 9, 23]},
  {date: "1995-12-10", numbers: [41, 7, 36, 33, 6, 14]},
  {date: "1995-12-17", numbers: [5, 14, 10, 9, 3, 15]},
  {date: "1996-01-02", numbers: [28, 14, 24, 20, 37, 33]},
  {date: "1996-01-14", numbers: [4, 32, 2, 20, 7, 35]},
  {date: "1996-01-21", numbers: [12, 14, 27, 5, 19, 42]},
  {date: "1996-01-28", numbers: [7, 38, 43, 18, 5, 12]},
  {date: "1996-02-04", numbers: [11, 19, 46, 20, 12, 29]},
  {date: "1996-02-11", numbers: [30, 16, 5, 22, 15, 21]},
  {date: "1996-02-18", numbers: [37, 47, 14, 6, 38, 45]},
  {date: "1996-02-25", numbers: [19, 10, 45, 17, 47, 23]},
  {date: "1996-03-03", numbers: [28, 48, 21, 35, 16, 33]},
  {date: "1996-03-10", numbers: [45, 18, 12, 25, 9, 22]},
  {date: "1996-03-17", numbers: [22, 32, 13, 46, 5, 10]},
  {date: "1996-03-24", numbers: [40, 34, 7, 46, 41, 27]},
  {date: "1996-03-31", numbers: [27, 17, 40, 48, 30, 44]},
  {date: "1996-04-07", numbers: [25, 38, 3, 9, 46, 47]},
  {date: "1996-04-14", numbers: [21, 38, 36, 31, 15, 11]},
  {date: "1996-04-21", numbers: [5, 25, 42, 46, 26, 15]},
  {date: "1996-04-28", numbers: [25, 10, 18, 8, 31, 44]},
  {date: "1996-05-05", numbers: [4, 14, 13, 32, 35, 19]},
  {date: "1996-05-12", numbers: [11, 16, 22, 35, 20, 23]},
  {date: "1996-05-19", numbers: [14, 38, 34, 49, 37, 42]},
  {date: "1996-05-26", numbers: [10, 29, 21, 19, 31, 26]},
  {date: "1996-06-02", numbers: [44, 23, 49, 43, 15, 16]},
  {date: "1996-06-09", numbers: [1, 35, 27, 9, 14, 36]},
  {date: "1996-06-16", numbers: [49, 40, 23, 16, 26, 24]},
  {date: "1996-06-23", numbers: [15, 22, 19, 2, 29, 31]},
  {date: "1996-06-30", numbers: [33, 14, 41, 2, 46, 21]},
  {date: "1996-07-07", numbers: [41, 8, 9, 12, 2, 44]},
  {date: "1996-07-14", numbers: [27, 6, 9, 13, 43, 18]},
  {date: "1996-07-21", numbers: [36, 10, 37, 16, 23, 46]},
  {date: "1996-07-28", numbers: [39, 32, 25, 17, 9, 42]},
  {date: "1996-08-04", numbers: [8, 40, 48, 15, 17, 14]},
  {date: "1996-08-11", numbers: [14, 46, 16, 49, 17, 1]},
  {date: "1996-08-18", numbers: [29, 28, 1, 5, 48, 47]},
  {date: "1996-08-25", numbers: [44, 1, 25, 20, 46, 34]},
  {date: "1996-09-01", numbers: [36, 13, 19, 15, 11, 3]},
  {date: "1996-09-08", numbers: [20, 39, 30, 1, 32, 2]},
  {date: "1996-09-15", numbers: [20, 5, 1, 8, 36, 7]},
  {date: "1996-09-22", numbers: [18, 4, 3, 37, 35, 25]},
  {date: "1996-09-29", numbers: [28, 4, 8, 29, 32, 20]},
  {date: "1996-10-06", numbers: [36, 13, 47, 27, 44, 7]},
  {date: "1996-10-13", numbers: [27, 23, 31, 34, 17, 25]},
  {date: "1996-10-20", numbers: [7, 2, 43, 42, 13, 5]},
  {date: "1996-10-27", numbers: [40, 20, 17, 39, 34, 9]},
  {date: "1996-11-03", numbers: [16, 48, 49, 45, 34, 15]},
  {date: "1996-11-10", numbers: [34, 22, 24, 12, 10, 37]},
  {date: "1996-11-17", numbers: [23, 32, 46, 6, 27, 14]},
  {date: "1996-11-24", numbers: [3, 15, 13, 35, 36, 5]},
  {date: "1996-12-01", numbers: [21, 45, 5, 7, 37, 48]},
  {date: "1996-12-08", numbers: [47, 20, 3, 16, 33, 35]},
  {date: "1996-12-15", numbers: [31, 15, 38, 46, 23, 10]},
  {date: "1996-12-22", numbers: [35, 48, 49, 23, 14, 29]},
  {date: "1997-01-02", numbers: [26, 25, 23, 5, 13, 35]},
  {date: "1997-01-12", numbers: [2, 24, 20, 18, 10, 29]},
  {date: "1997-01-19", numbers: [9, 43, 42, 33, 12, 23]},
  {date: "1997-01-26", numbers: [24, 27, 23, 33, 18, 29]},
  {date: "1997-02-02", numbers: [17, 11, 1, 20, 13, 23]},
  {date: "1997-02-09", numbers: [7, 48, 43, 4, 17, 45]},
  {date: "1997-02-16", numbers: [18, 17, 28, 8, 20, 5]},
  {date: "1997-02-23", numbers: [39, 18, 33, 15, 20, 23]},
  {date: "1997-03-02", numbers: [42, 36, 6, 10, 17, 16]},
  {date: "1997-03-09", numbers: [29, 27, 37, 38, 1, 12]},
  {date: "1997-03-16", numbers: [12, 9, 23, 46, 19, 37]},
  {date: "1997-03-23", numbers: [5, 13, 33, 37, 16, 22]},
  {date: "1997-03-30", numbers: [5, 11, 28, 18, 39, 27]},
  {date: "1997-04-06", numbers: [5, 49, 22, 8, 24, 16]},
  {date: "1997-04-13", numbers: [1, 41, 6, 25, 31, 14]},
  {date: "1997-04-20", numbers: [25, 3, 8, 24, 2, 27]},
  {date: "1997-04-29", numbers: [1, 13, 45, 20, 21, 12]},
  {date: "1997-05-04", numbers: [43, 33, 42, 46, 17, 7]},
  {date: "1997-05-11", numbers: [31, 45, 16, 29, 32, 28]},
  {date: "1997-05-18", numbers: [20, 26, 42, 36, 49, 9]},
  {date: "1997-05-25", numbers: [14, 15, 11, 46, 41, 16]},
  {date: "1997-06-01", numbers: [19, 23, 32, 18, 36, 15]},
  {date: "1997-06-08", numbers: [26, 37, 30, 27, 23, 39]},
  {date: "1997-06-15", numbers: [45, 17, 14, 29, 39, 27]},
  {date: "1997-06-22", numbers: [46, 43, 9, 40, 4, 34]},
  {date: "1997-06-29", numbers: [38, 35, 48, 9, 34, 37]},
  {date: "1997-07-06", numbers: [23, 16, 17, 9, 15, 26]},
  {date: "1997-07-13", numbers: [33, 32, 9, 1, 42, 3]},
  {date: "1997-07-20", numbers: [14, 28, 10, 24, 2, 11]},
  {date: "1997-07-27", numbers: [4, 44, 20, 10, 7, 11]},
  {date: "1997-08-03", numbers: [24, 42, 2, 30, 26, 28]},
  {date: "1997-08-10", numbers: [2, 48, 9, 34, 42, 8]},
  {date: "1997-08-17", numbers: [46, 37, 3, 43, 34, 30]},
  {date: "1997-08-24", numbers: [46, 13, 48, 29, 39, 3]},
  {date: "1997-08-31", numbers: [38, 49, 22, 34, 46, 14]},
  {date: "1997-09-07", numbers: [19, 46, 13, 9, 15, 14]},
  {date: "1997-09-14", numbers: [40, 21, 47, 2, 31, 45]},
  {date: "1997-09-21", numbers: [20, 26, 43, 17, 41, 38]},
  {date: "1997-09-28", numbers: [43, 17, 22, 20, 34, 23]},
  {date: "1997-10-05", numbers: [25, 19, 12, 45, 49, 5]},
  {date: "1997-10-12", numbers: [48, 1, 43, 32, 26, 24]},
  {date: "1997-10-19", numbers: [41, 42, 13, 4, 3, 49]},
  {date: "1997-10-26", numbers: [26, 10, 4, 36, 25, 46]},
  {date: "1997-11-02", numbers: [30, 1, 46, 28, 48, 45]},
  {date: "1997-11-09", numbers: [30, 3, 37, 5, 20, 2]},
  {date: "1997-11-16", numbers: [31, 22, 29, 13, 15, 21]},
  {date: "1997-11-23", numbers: [38, 6, 34, 35, 15, 46]},
  {date: "1997-11-30", numbers: [30, 1, 42, 3, 18, 49]},
  {date: "1997-12-07", numbers: [4, 16, 39, 29, 45, 11]},
  {date: "1997-12-14", numbers: [27, 42, 31, 6, 9, 21]},
  {date: "1997-12-21", numbers: [24, 12, 46, 6, 26, 27]},
  {date: "1998-01-04", numbers: [17, 23, 5, 35, 34, 24]},
  {date: "1998-01-11", numbers: [27, 32, 20, 28, 29, 12]},
  {date: "1998-01-18", numbers: [16, 33, 21, 8, 23, 43]},
  {date: "1998-01-25", numbers: [26, 46, 1, 27, 30, 13]},
  {date: "1998-02-01", numbers: [23, 10, 48, 44, 19, 18]},
  {date: "1998-02-08", numbers: [42, 2, 4, 21, 1, 29]},
  {date: "1998-02-15", numbers: [5, 33, 36, 31, 39, 46]},
  {date: "1998-02-22", numbers: [24, 35, 23, 7, 15, 38]},
  {date: "1998-03-01", numbers: [17, 34, 11, 2, 45, 30]},
  {date: "1998-03-08", numbers: [33, 14, 49, 46, 36, 45]},
  {date: "1998-03-15", numbers: [49, 35, 33, 24, 36, 43]},
  {date: "1998-03-22", numbers: [4, 48, 27, 26, 30, 44]},
  {date: "1998-03-29", numbers: [39, 13, 23, 4, 8, 29]},
  {date: "1998-04-05", numbers: [8, 7, 18, 25, 41, 12]},
  {date: "1998-04-12", numbers: [47, 11, 25, 15, 40, 7]},
  {date: "1998-04-21", numbers: [9, 26, 43, 5, 7, 24]},
  {date: "1998-04-26", numbers: [22, 1, 45, 34, 15, 26]},
  {date: "1998-05-03", numbers: [33, 22, 49, 31, 35, 10]},
  {date: "1998-05-10", numbers: [43, 40, 48, 5, 11, 29]},
  {date: "1998-05-17", numbers: [21, 44, 13, 5, 9, 41]},
  {date: "1998-05-24", numbers: [20, 8, 43, 46, 48, 26]},
  {date: "1998-05-31", numbers: [11, 33, 46, 15, 4, 44]},
  {date: "1998-06-07", numbers: [44, 32, 45, 21, 31, 37]},
  {date: "1998-06-14", numbers: [33, 43, 5, 29, 47, 21]},
  {date: "1998-06-21", numbers: [16, 5, 17, 13, 4, 28]},
  {date: "1998-06-28", numbers: [48, 30, 15, 22, 5, 35]},
  {date: "1998-07-05", numbers: [21, 43, 17, 14, 12, 11]},
  {date: "1998-07-12", numbers: [16, 22, 13, 20, 6, 1]},
  {date: "1998-07-19", numbers: [9, 18, 11, 49, 21, 29]},
  {date: "1998-07-26", numbers: [28, 5, 8, 17, 13, 24]},
  {date: "1998-08-02", numbers: [39, 29, 15, 33, 23, 7]},
  {date: "1998-08-09", numbers: [47, 29, 36, 42, 25, 20]},
  {date: "1998-08-16", numbers: [34, 25, 3, 43, 33, 6]},
  {date: "1998-08-23", numbers: [15, 23, 21, 29, 44, 6]},
  {date: "1998-08-30", numbers: [49, 18, 30, 10, 22, 27]},
  {date: "1998-09-06", numbers: [15, 27, 35, 17, 7, 44]},
  {date: "1998-09-13", numbers: [22, 44, 42, 10, 14, 49]},
  {date: "1998-09-20", numbers: [20, 3, 9, 39, 24, 11]},
  {date: "1998-09-27", numbers: [18, 3, 26, 16, 13, 5]},
  {date: "1998-10-04", numbers: [40, 45, 24, 33, 13, 9]},
  {date: "1998-10-11", numbers: [13, 18, 32, 38, 46, 26]},
  {date: "1998-10-18", numbers: [22, 13, 42, 36, 26, 38]},
  {date: "1998-10-25", numbers: [31, 43, 44, 13, 32, 16]},
  {date: "1998-11-01", numbers: [6, 19, 36, 25, 2, 32]},
  {date: "1998-11-08", numbers: [7, 48, 17, 26, 10, 15]},
  {date: "1998-11-15", numbers: [13, 34, 39, 28, 15, 37]},
  {date: "1998-11-22", numbers: [12, 37, 4, 30, 46, 36]},
  {date: "1998-11-29", numbers: [30, 22, 19, 34, 32, 15]},
  {date: "1998-12-06", numbers: [25, 16, 27, 33, 26, 22]},
  {date: "1998-12-13", numbers: [28, 8, 43, 31, 18, 24]},
  {date: "1998-12-20", numbers: [19, 36, 48, 23, 41, 29]},
  {date: "1999-01-03", numbers: [13, 12, 18, 38, 25, 33]},
  {date: "1999-01-10", numbers: [11, 36, 41, 38, 43, 18]},
  {date: "1999-01-17", numbers: [12, 45, 25, 18, 30, 21]},
  {date: "1999-01-24", numbers: [37, 10, 35, 41, 20, 43]},
  {date: "1999-01-31", numbers: [23, 28, 36, 3, 2, 38]},
  {date: "1999-02-07", numbers: [37, 43, 38, 36, 40, 20]},
  {date: "1999-02-14", numbers: [35, 17, 48, 45, 36, 1]},
  {date: "1999-02-21", numbers: [38, 40, 43, 33, 32, 29]},
  {date: "1999-02-28", numbers: [10, 15, 3, 43, 37, 28]},
  {date: "1999-03-07", numbers: [13, 26, 45, 2, 11, 31]},
  {date: "1999-03-14", numbers: [48, 24, 29, 23, 7, 32]},
  {date: "1999-03-21", numbers: [47, 9, 41, 2, 30, 10]},
  {date: "1999-03-28", numbers: [30, 17, 49, 27, 3, 5]},
  {date: "1999-04-04", numbers: [11, 31, 35, 18, 7, 22]},
  {date: "1999-04-13", numbers: [17, 26, 1, 29, 6, 10]},
  {date: "1999-04-18", numbers: [49, 29, 45, 31, 39, 26]},
  {date: "1999-04-25", numbers: [8, 42, 6, 47, 2, 20]},
  {date: "1999-05-02", numbers: [3, 24, 27, 29, 26, 23]},
  {date: "1999-05-09", numbers: [24, 2, 13, 30, 26, 27]},
  {date: "1999-05-16", numbers: [36, 33, 26, 3, 10, 14]},
  {date: "1999-05-23", numbers: [26, 33, 38, 30, 3, 23]},
  {date: "1999-05-30", numbers: [44, 20, 25, 16, 31, 24]},
  {date: "1999-06-06", numbers: [23, 17, 9, 44, 11, 31]},
  {date: "1999-06-13", numbers: [49, 26, 24, 19, 11, 15]},
  {date: "1999-06-20", numbers: [18, 6, 32, 30, 24, 40]},
  {date: "1999-06-27", numbers: [49, 27, 26, 41, 16, 31]},
  {date: "1999-07-04", numbers: [12, 13, 15, 38, 43, 34]},
  {date: "1999-07-11", numbers: [24, 8, 10, 13, 28, 44]},
  {date: "1999-07-18", numbers: [14, 20, 24, 9, 4, 39]},
  {date: "1999-07-25", numbers: [16, 20, 39, 49, 28, 22]},
  {date: "1999-08-01", numbers: [48, 25, 2, 22, 31, 43]},
  {date: "1999-08-08", numbers: [21, 34, 18, 32, 35, 10]},
  {date: "1999-08-15", numbers: [30, 27, 9, 40, 44, 7]},
  {date: "1999-08-22", numbers: [49, 16, 6, 43, 36, 9]},
  {date: "1999-08-29", numbers: [14, 23, 22, 30, 6, 15]},
  {date: "1999-09-05", numbers: [12, 46, 49, 47, 35, 45]},
  {date: "1999-09-12", numbers: [46, 17, 8, 26, 15, 23]},
  {date: "1999-09-19", numbers: [33, 37, 19, 47, 22, 26]},
  {date: "1999-09-26", numbers: [49, 46, 26, 12, 22, 39]},
  {date: "1999-10-03", numbers: [13, 19, 33, 42, 3, 28]},
  {date: "1999-10-10", numbers: [18, 24, 17, 46, 41, 12]},
  {date: "1999-10-17", numbers: [45, 48, 33, 5, 9, 15]},
  {date: "1999-10-24", numbers: [35, 47, 6, 20, 5, 45]},
  {date: "1999-10-31", numbers: [27, 38, 34, 11, 5, 47]},
  {date: "1999-11-07", numbers: [36, 40, 8, 33, 5, 49]},
  {date: "1999-11-14", numbers: [25, 47, 43, 6, 18, 20]},
  {date: "1999-11-21", numbers: [6, 23, 2, 43, 13, 40]},
  {date: "1999-11-28", numbers: [31, 10, 39, 11, 35, 29]},
  {date: "1999-12-05", numbers: [18, 38, 41, 33, 6, 7]},
  {date: "1999-12-12", numbers: [39, 38, 26, 24, 18, 16]},
  {date: "1999-12-19", numbers: [31, 37, 32, 38, 22, 13]},
  {date: "1999-12-26", numbers: [12, 32, 4, 30, 41, 33]},
  {date: "2000-01-02", numbers: [13, 8, 15, 2, 1, 37]},
  {date: "2000-01-09", numbers: [33, 29, 39, 17, 41, 13]},
  {date: "2000-01-16", numbers: [16, 7, 22, 13, 1, 6]},
  {date: "2000-01-23", numbers: [46, 41, 17, 6, 29, 2]},
  {date: "2000-01-30", numbers: [14, 4, 13, 34, 12, 40]},
  {date: "2000-02-06", numbers: [8, 12, 42, 6, 20, 29]},
  {date: "2000-02-13", numbers: [2, 47, 23, 48, 33, 8]},
  {date: "2000-02-20", numbers: [23, 44, 6, 5, 45, 11]},
  {date: "2000-02-27", numbers: [23, 12, 36, 38, 41, 17]},
  {date: "2000-03-05", numbers: [8, 48, 28, 23, 7, 17]},
  {date: "2000-03-12", numbers: [36, 49, 16, 2, 47, 19]},
  {date: "2000-03-19", numbers: [23, 37, 42, 47, 21, 48]},
  {date: "2000-03-26", numbers: [30, 11, 23, 1, 6, 43]},
  {date: "2000-04-02", numbers: [5, 10, 25, 18, 35, 27]},
  {date: "2000-04-09", numbers: [3, 31, 22, 35, 40, 18]},
  {date: "2000-04-16", numbers: [43, 34, 7, 30, 28, 3]},
  {date: "2000-04-23", numbers: [3, 20, 45, 42, 11, 31]},
  {date: "2000-04-30", numbers: [19, 30, 33, 31, 1, 41]},
  {date: "2000-05-07", numbers: [30, 37, 33, 34, 29, 16]},
  {date: "2000-05-14", numbers: [5, 4, 27, 3, 41, 37]},
  {date: "2000-05-21", numbers: [25, 28, 39, 40, 17, 37]},
  {date: "2000-05-28", numbers: [12, 6, 46, 37, 47, 17]},
  {date: "2000-06-04", numbers: [49, 23, 13, 10, 8, 38]},
  {date: "2000-06-11", numbers: [45, 7, 36, 24, 21, 25]},
  {date: "2000-06-18", numbers: [48, 9, 32, 20, 17, 6]},
  {date: "2000-06-25", numbers: [4, 3, 13, 31, 34, 49]},
  {date: "2000-07-02", numbers: [29, 43, 36, 4, 30, 47]},
  {date: "2000-07-09", numbers: [11, 16, 27, 3, 45, 4]},
  {date: "2000-07-16", numbers: [11, 40, 47, 22, 9, 48]},
  {date: "2000-07-23", numbers: [4, 23, 20, 2, 14, 43]},
  {date: "2000-07-30", numbers: [45, 23, 16, 35, 24, 1]},
  {date: "2000-08-06", numbers: [12, 18, 29, 49, 9, 11]},
  {date: "2000-08-13", numbers: [35, 47, 26, 16, 18, 48]},
  {date: "2000-08-20", numbers: [34, 35, 25, 2, 8, 10]},
  {date: "2000-08-27", numbers: [1, 36, 38, 29, 15, 21]},
  {date: "2000-09-03", numbers: [33, 22, 3, 2, 34, 11]},
  {date: "2000-09-10", numbers: [17, 29, 14, 46, 11, 23]},
  {date: "2000-09-17", numbers: [4, 12, 47, 26, 11, 17]},
  {date: "2000-09-24", numbers: [20, 18, 10, 38, 22, 41]},
  {date: "2000-10-01", numbers: [15, 33, 23, 9, 47, 24]},
  {date: "2000-10-08", numbers: [30, 25, 1, 5, 18, 9]},
  {date: "2000-10-15", numbers: [13, 4, 11, 8, 9, 27]},
  {date: "2000-10-22", numbers: [15, 14, 36, 21, 11, 9]},
  {date: "2000-10-29", numbers: [44, 9, 7, 34, 33, 29]},
  {date: "2000-11-05", numbers: [23, 32, 37, 27, 46, 39]},
  {date: "2000-11-12", numbers: [37, 20, 21, 32, 6, 44]},
  {date: "2000-11-19", numbers: [3, 46, 26, 36, 17, 10]},
  {date: "2000-11-26", numbers: [46, 36, 47, 6, 25, 38]},
  {date: "2000-12-03", numbers: [31, 47, 29, 40, 7, 5]},
  {date: "2000-12-10", numbers: [17, 36, 45, 47, 19, 10]},
  {date: "2000-12-17", numbers: [27, 21, 31, 4, 19, 35]},
  {date: "2000-12-24", numbers: [13, 16, 34, 15, 17, 9]},
  {date: "2001-01-02", numbers: [16, 46, 18, 15, 25, 31]},
  {date: "2001-01-07", numbers: [18, 47, 45, 20, 9, 39]},
  {date: "2001-01-14", numbers: [30, 7, 2, 32, 49, 12]},
  {date: "2001-01-21", numbers: [40, 14, 13, 26, 19, 32]},
  {date: "2001-01-28", numbers: [7, 11, 20, 10, 32, 21]},
  {date: "2001-02-04", numbers: [14, 24, 39, 21, 36, 43]},
  {date: "2001-02-11", numbers: [39, 15, 20, 16, 21, 19]},
  {date: "2001-02-18", numbers: [34, 6, 32, 29, 4, 19]},
  {date: "2001-02-25", numbers: [32, 4, 1, 40, 38, 25]},
  {date: "2001-03-04", numbers: [8, 38, 27, 16, 19, 40]},
  {date: "2001-03-11", numbers: [42, 7, 14, 15, 21, 12]},
  {date: "2001-03-18", numbers: [22, 36, 2, 41, 1, 9]},
  {date: "2001-03-25", numbers: [9, 26, 7, 39, 18, 15]},
  {date: "2001-04-01", numbers: [43, 32, 30, 16, 48, 15]},
  {date: "2001-04-08", numbers: [43, 44, 36, 23, 34, 21]},
  {date: "2001-04-15", numbers: [5, 6, 9, 44, 8, 34]},
  {date: "2001-04-22", numbers: [6, 36, 14, 18, 19, 21]},
  {date: "2001-04-29", numbers: [46, 38, 12, 41, 30, 10]},
  {date: "2001-05-06", numbers: [34, 17, 44, 13, 47, 12]},
  {date: "2001-05-13", numbers: [24, 33, 3, 37, 26, 32]},
  {date: "2001-05-20", numbers: [3, 41, 47, 14, 23, 8]},
  {date: "2001-05-27", numbers: [19, 47, 28, 38, 29, 9]},
  {date: "2001-06-03", numbers: [10, 3, 9, 39, 12, 14]},
  {date: "2001-06-10", numbers: [19, 22, 16, 17, 42, 33]},
  {date: "2001-06-17", numbers: [12, 24, 28, 44, 17, 39]},
  {date: "2001-06-24", numbers: [43, 44, 48, 3, 46, 41]},
  {date: "2001-07-01", numbers: [49, 17, 41, 13, 27, 8]},
  {date: "2001-07-08", numbers: [18, 42, 8, 23, 21, 22]},
  {date: "2001-07-15", numbers: [1, 42, 36, 29, 25, 30]},
  {date: "2001-07-22", numbers: [30, 40, 42, 22, 19, 13]},
  {date: "2001-07-29", numbers: [12, 2, 47, 23, 34, 32]},
  {date: "2001-08-05", numbers: [30, 43, 22, 13, 49, 32]},
  {date: "2001-08-12", numbers: [45, 26, 47, 22, 18, 17]},
  {date: "2001-08-19", numbers: [34, 41, 33, 47, 23, 35]},
  {date: "2001-08-26", numbers: [27, 16, 10, 21, 22, 38]},
  {date: "2001-09-02", numbers: [2, 1, 13, 42, 25, 3]},
  {date: "2001-09-09", numbers: [6, 8, 40, 41, 43, 9]},
  {date: "2001-09-16", numbers: [26, 23, 29, 41, 15, 39]},
  {date: "2001-09-23", numbers: [28, 33, 18, 16, 45, 9]},
  {date: "2001-09-30", numbers: [35, 47, 40, 37, 15, 39]},
  {date: "2001-10-07", numbers: [18, 15, 33, 41, 49, 46]},
  {date: "2001-10-14", numbers: [31, 45, 44, 27, 20, 42]},
  {date: "2001-10-21", numbers: [44, 45, 29, 1, 37, 21]},
  {date: "2001-10-28", numbers: [41, 18, 33, 39, 35, 28]},
  {date: "2001-11-04", numbers: [4, 23, 32, 10, 30, 13]},
  {date: "2001-11-11", numbers: [2, 37, 1, 33, 40, 23]},
  {date: "2001-11-18", numbers: [16, 21, 23, 25, 15, 3]},
  {date: "2001-11-25", numbers: [39, 22, 49, 33, 40, 30]},
  {date: "2001-12-02", numbers: [43, 25, 16, 30, 32, 27]},
  {date: "2001-12-09", numbers: [46, 4, 24, 22, 2, 40]},
  {date: "2001-12-16", numbers: [23, 34, 48, 42, 33, 19]},
  {date: "2001-12-23", numbers: [29, 40, 8, 2, 22, 1]},
  {date: "2002-01-02", numbers: [14, 18, 29, 47, 28, 6]},
  {date: "2002-01-06", numbers: [26, 39, 6, 25, 14, 16]},
  {date: "2002-01-13", numbers: [10, 31, 33, 43, 45, 41]},
  {date: "2002-01-20", numbers: [24, 29, 35, 5, 43, 30]},
  {date: "2002-01-27", numbers: [13, 49, 47, 15, 5, 35]},
  {date: "2002-02-03", numbers: [4, 30, 39, 14, 20, 42]},
  {date: "2002-02-10", numbers: [43, 32, 39, 42, 36, 14]},
  {date: "2002-02-17", numbers: [22, 36, 47, 34, 16, 3]},
  {date: "2002-02-24", numbers: [32, 6, 17, 36, 30, 27]},
  {date: "2002-03-03", numbers: [38, 49, 48, 32, 26, 24]},
  {date: "2002-03-10", numbers: [11, 27, 41, 14, 7, 22]},
  {date: "2002-03-17", numbers: [5, 34, 47, 45, 9, 1]},
  {date: "2002-03-24", numbers: [44, 10, 21, 37, 36, 24]},
  {date: "2002-03-31", numbers: [24, 47, 32, 33, 44, 22]},
  {date: "2002-04-07", numbers: [47, 13, 4, 44, 39, 29]},
  {date: "2002-04-14", numbers: [14, 48, 5, 9, 11, 34]},
  {date: "2002-04-21", numbers: [22, 21, 25, 2, 1, 49]},
  {date: "2002-04-28", numbers: [38, 3, 6, 12, 43, 40]},
  {date: "2002-05-05", numbers: [7, 35, 48, 47, 40, 17]},
  {date: "2002-05-12", numbers: [44, 45, 36, 32, 33, 28]},
  {date: "2002-05-19", numbers: [1, 42, 7, 4, 9, 10]},
  {date: "2002-05-26", numbers: [47, 19, 38, 1, 48, 21]},
  {date: "2002-06-02", numbers: [2, 27, 19, 6, 10, 30]},
  {date: "2002-06-09", numbers: [31, 25, 29, 10, 20, 24]},
  {date: "2002-06-16", numbers: [33, 45, 22, 39, 14, 27]},
  {date: "2002-06-23", numbers: [41, 47, 12, 5, 31, 18]},
  {date: "2002-06-30", numbers: [34, 27, 33, 48, 41, 49]},
  {date: "2002-07-07", numbers: [6, 36, 1, 18, 34, 33]},
  {date: "2002-07-14", numbers: [29, 5, 21, 6, 10, 38]},
  {date: "2002-07-21", numbers: [41, 5, 28, 39, 47, 9]},
  {date: "2002-07-28", numbers: [1, 20, 36, 11, 3, 28]},
  {date: "2002-08-04", numbers: [29, 21, 14, 32, 46, 23]},
  {date: "2002-08-11", numbers: [6, 48, 9, 12, 36, 10]},
  {date: "2002-08-18", numbers: [44, 28, 47, 15, 7, 38]},
  {date: "2002-08-25", numbers: [19, 18, 28, 8, 22, 31]},
  {date: "2002-09-01", numbers: [4, 16, 37, 20, 1, 43]},
  {date: "2002-09-08", numbers: [31, 48, 45, 47, 6, 17]},
  {date: "2002-09-15", numbers: [36, 15, 14, 8, 38, 12]},
  {date: "2002-09-22", numbers: [33, 7, 43, 29, 13, 37]},
  {date: "2002-09-29", numbers: [21, 14, 3, 44, 4, 24]},
  {date: "2002-10-06", numbers: [27, 35, 7, 20, 6, 24]},
  {date: "2002-10-13", numbers: [48, 38, 25, 6, 37, 22]},
  {date: "2002-10-20", numbers: [15, 30, 44, 17, 5, 48]},
  {date: "2002-10-27", numbers: [38, 15, 44, 13, 11, 41]},
  {date: "2002-11-03", numbers: [27, 46, 11, 17, 49, 21]},
  {date: "2002-11-10", numbers: [24, 22, 9, 47, 8, 38]},
  {date: "2002-11-17", numbers: [6, 44, 27, 29, 24, 47]},
  {date: "2002-11-24", numbers: [27, 24, 7, 17, 34, 4]},
  {date: "2002-12-01", numbers: [30, 24, 32, 37, 16, 3]},
  {date: "2002-12-08", numbers: [8, 2, 5, 14, 10, 25]},
  {date: "2002-12-15", numbers: [15, 32, 11, 38, 18, 41]},
  {date: "2002-12-22", numbers: [6, 47, 44, 11, 22, 8]},
  {date: "2002-12-31", numbers: [8, 42, 32, 41, 16, 3]},
  {date: "2003-01-12", numbers: [38, 8, 23, 24, 27, 1]},
  {date: "2003-01-19", numbers: [45, 2, 30, 24, 23, 28]},
  {date: "2003-01-26", numbers: [38, 36, 16, 26, 18, 41]},
  {date: "2003-02-02", numbers: [35, 26, 49, 45, 40, 22]},
  {date: "2003-02-09", numbers: [31, 6, 37, 18, 10, 16]},
  {date: "2003-02-16", numbers: [7, 26, 47, 48, 24, 33]},
  {date: "2003-02-23", numbers: [12, 19, 23, 37, 42, 14]},
  {date: "2003-03-02", numbers: [9, 18, 46, 6, 10, 28]},
  {date: "2003-03-09", numbers: [20, 38, 15, 33, 30, 8]},
  {date: "2003-03-16", numbers: [33, 28, 13, 31, 45, 18]},
  {date: "2003-03-23", numbers: [43, 14, 19, 31, 15, 18]},
  {date: "2003-03-30", numbers: [29, 31, 46, 3, 2, 21]},
  {date: "2003-04-06", numbers: [29, 28, 7, 12, 43, 40]},
  {date: "2003-04-13", numbers: [39, 12, 5, 19, 41, 38]},
  {date: "2003-04-20", numbers: [2, 38, 23, 48, 8, 12]},
  {date: "2003-04-27", numbers: [26, 45, 13, 39, 35, 11]},
  {date: "2003-05-04", numbers: [10, 48, 32, 47, 13, 44]},
  {date: "2003-05-11", numbers: [8, 13, 14, 9, 22, 21]},
  {date: "2003-05-18", numbers: [15, 2, 8, 9, 44, 36]},
  {date: "2003-05-25", numbers: [2, 20, 29, 47, 4, 12]},
  {date: "2003-06-01", numbers: [29, 33, 16, 14, 1, 48]},
  {date: "2003-06-08", numbers: [14, 30, 13, 38, 39, 9]},
  {date: "2003-06-15", numbers: [17, 41, 44, 34, 30, 24]},
  {date: "2003-06-22", numbers: [14, 37, 46, 38, 27, 10]},
  {date: "2003-06-29", numbers: [12, 15, 18, 33, 45, 40]},
  {date: "2003-07-06", numbers: [36, 25, 20, 12, 48, 43]},
  {date: "2003-07-13", numbers: [45, 15, 20, 22, 41, 25]},
  {date: "2003-07-20", numbers: [5, 4, 24, 34, 9, 8]},
  {date: "2003-07-27", numbers: [33, 30, 2, 23, 32, 17]},
  {date: "2003-08-03", numbers: [48, 1, 14, 17, 12, 44]},
  {date: "2003-08-10", numbers: [14, 49, 47, 6, 24, 39]},
  {date: "2003-08-17", numbers: [30, 23, 15, 9, 5, 48]},
  {date: "2003-08-24", numbers: [7, 5, 4, 40, 48, 28]},
  {date: "2003-08-31", numbers: [29, 24, 40, 38, 17, 33]},
  {date: "2003-09-07", numbers: [47, 2, 5, 1, 45, 15]},
  {date: "2003-09-14", numbers: [36, 9, 4, 41, 28, 13]},
  {date: "2003-09-21", numbers: [46, 14, 16, 2, 22, 19]},
  {date: "2003-09-28", numbers: [46, 49, 1, 17, 42, 23]},
  {date: "2003-10-05", numbers: [27, 42, 3, 13, 46, 38]},
  {date: "2003-10-12", numbers: [43, 28, 9, 24, 2, 18]},
  {date: "2003-10-19", numbers: [16, 13, 24, 43, 26, 4]},
  {date: "2003-10-26", numbers: [14, 48, 20, 37, 38, 5]},
  {date: "2003-11-02", numbers: [6, 40, 46, 45, 30, 29]},
  {date: "2003-11-09", numbers: [40, 18, 46, 22, 41, 45]},
  {date: "2003-11-16", numbers: [18, 2, 3, 33, 27, 21]},
  {date: "2003-11-23", numbers: [19, 17, 27, 3, 31, 15]},
  {date: "2003-11-30", numbers: [4, 32, 9, 29, 11, 10]},
  {date: "2003-12-07", numbers: [12, 45, 42, 27, 23, 20]},
  {date: "2003-12-14", numbers: [27, 42, 44, 24, 41, 2]},
  {date: "2003-12-21", numbers: [36, 17, 8, 37, 44, 20]},
  {date: "2004-01-04", numbers: [3, 2, 35, 45, 37, 31]},
  {date: "2004-01-11", numbers: [32, 36, 28, 11, 24, 25]},
  {date: "2004-01-18", numbers: [34, 12, 11, 26, 42, 13]},
  {date: "2004-01-25", numbers: [6, 20, 9, 10, 49, 43]},
  {date: "2004-02-01", numbers: [14, 42, 37, 40, 31, 6]},
  {date: "2004-02-08", numbers: [5, 33, 3, 48, 6, 15]},
  {date: "2004-02-15", numbers: [38, 6, 31, 33, 8, 7]},
  {date: "2004-02-22", numbers: [12, 7, 32, 10, 13, 22]},
  {date: "2004-02-29", numbers: [23, 10, 13, 17, 30, 20]},
  {date: "2004-03-07", numbers: [40, 47, 27, 34, 16, 8]},
  {date: "2004-03-14", numbers: [26, 21, 41, 36, 44, 12]},
  {date: "2004-03-21", numbers: [34, 3, 39, 4, 27, 37]},
  {date: "2004-03-28", numbers: [17, 10, 46, 11, 13, 19]},
  {date: "2004-04-04", numbers: [38, 45, 5, 15, 24, 1]},
  {date: "2004-04-11", numbers: [28, 9, 24, 17, 5, 12]},
  {date: "2004-04-18", numbers: [35, 12, 42, 44, 14, 40]},
  {date: "2004-04-25", numbers: [13, 15, 6, 29, 3, 11]},
  {date: "2004-05-02", numbers: [31, 39, 32, 6, 49, 46]},
  {date: "2004-05-09", numbers: [11, 9, 6, 32, 41, 38]},
  {date: "2004-05-16", numbers: [33, 8, 43, 24, 7, 36]},
  {date: "2004-05-23", numbers: [24, 44, 27, 5, 41, 6]},
  {date: "2004-05-30", numbers: [23, 19, 36, 21, 37, 38]},
  {date: "2004-06-06", numbers: [30, 1, 7, 20, 28, 29]},
  {date: "2004-06-13", numbers: [10, 23, 37, 12, 14, 9]},
  {date: "2004-06-20", numbers: [3, 49, 48, 27, 28, 2]},
  {date: "2004-06-27", numbers: [37, 13, 32, 31, 49, 29]},
  {date: "2004-07-04", numbers: [36, 4, 12, 17, 8, 5]},
  {date: "2004-07-11", numbers: [18, 17, 36, 5, 11, 23]},
  {date: "2004-07-18", numbers: [32, 3, 46, 36, 27, 13]},
  {date: "2004-07-25", numbers: [30, 26, 44, 5, 14, 8]},
  {date: "2004-08-01", numbers: [27, 49, 32, 6, 48, 25]},
  {date: "2004-08-08", numbers: [9, 44, 19, 37, 25, 2]},
  {date: "2004-08-15", numbers: [43, 13, 32, 31, 22, 1]},
  {date: "2004-08-22", numbers: [20, 9, 12, 41, 27, 42]},
  {date: "2004-08-29", numbers: [40, 32, 23, 30, 49, 1]},
  {date: "2004-09-05", numbers: [16, 28, 4, 26, 1, 34]},
  {date: "2004-09-12", numbers: [48, 20, 49, 2, 3, 19]},
  {date: "2004-09-19", numbers: [27, 43, 42, 1, 3, 28]},
  {date: "2004-09-26", numbers: [8, 36, 37, 11, 29, 48]},
  {date: "2004-10-03", numbers: [42, 29, 6, 13, 44, 22]},
  {date: "2004-10-10", numbers: [28, 10, 46, 40, 25, 24]},
  {date: "2004-10-17", numbers: [44, 14, 10, 3, 22, 16]},
  {date: "2004-10-24", numbers: [17, 19, 44, 23, 26, 16]},
  {date: "2004-10-31", numbers: [29, 7, 46, 12, 6, 11]},
  {date: "2004-11-07", numbers: [31, 37, 17, 49, 29, 43]},
  {date: "2004-11-14", numbers: [33, 29, 40, 21, 16, 11]},
  {date: "2004-11-21", numbers: [4, 30, 2, 49, 34, 32]},
  {date: "2004-11-28", numbers: [30, 45, 24, 26, 36, 19]},
  {date: "2004-12-05", numbers: [15, 11, 23, 22, 26, 12]},
  {date: "2004-12-12", numbers: [29, 14, 43, 7, 36, 4]},
  {date: "2004-12-19", numbers: [28, 16, 15, 26, 44, 36]},
  {date: "2005-01-02", numbers: [4, 35, 34, 1, 30, 9]},
  {date: "2005-01-09", numbers: [11, 6, 17, 3, 49, 31]},
  {date: "2005-01-16", numbers: [30, 19, 48, 42, 17, 13]},
  {date: "2005-01-23", numbers: [29, 12, 21, 46, 33, 5]},
  {date: "2005-01-30", numbers: [3, 34, 5, 39, 21, 16]},
  {date: "2005-02-06", numbers: [29, 20, 26, 25, 24, 48]},
  {date: "2005-02-13", numbers: [41, 46, 36, 14, 17, 21]},
  {date: "2005-02-20", numbers: [46, 21, 39, 2, 24, 30]},
  {date: "2005-02-27", numbers: [12, 47, 33, 4, 41, 3]},
  {date: "2005-03-06", numbers: [13, 24, 15, 36, 10, 32]},
  {date: "2005-03-13", numbers: [19, 47, 22, 20, 30, 48]},
  {date: "2005-03-20", numbers: [37, 3, 17, 32, 31, 26]},
  {date: "2005-03-27", numbers: [14, 16, 18, 7, 35, 38]},
  {date: "2005-04-03", numbers: [14, 29, 24, 15, 3, 22]},
  {date: "2005-04-10", numbers: [9, 14, 34, 44, 37, 8]},
  {date: "2005-04-17", numbers: [36, 4, 27, 1, 39, 43]},
  {date: "2005-04-24", numbers: [35, 33, 32, 47, 11, 29]},
  {date: "2005-05-01", numbers: [3, 44, 33, 12, 26, 43]},
  {date: "2005-05-08", numbers: [45, 15, 47, 41, 28, 7]},
  {date: "2005-05-15", numbers: [48, 14, 41, 20, 24, 17]},
  {date: "2005-05-22", numbers: [48, 17, 39, 43, 10, 29]},
  {date: "2005-05-29", numbers: [21, 31, 34, 32, 47, 4]},
  {date: "2005-06-05", numbers: [42, 11, 17, 44, 22, 3]},
  {date: "2005-06-12", numbers: [4, 20, 45, 30, 38, 19]},
  {date: "2005-06-19", numbers: [14, 49, 26, 31, 36, 38]},
  {date: "2005-06-26", numbers: [44, 30, 23, 39, 29, 34]},
  {date: "2005-07-03", numbers: [41, 15, 44, 3, 23, 40]},
  {date: "2005-07-10", numbers: [39, 2, 48, 12, 20, 37]},
  {date: "2005-07-17", numbers: [12, 26, 1, 35, 2, 19]},
  {date: "2005-07-24", numbers: [45, 26, 8, 1, 19, 42]},
  {date: "2005-07-31", numbers: [17, 40, 28, 8, 1, 29]},
  {date: "2005-08-07", numbers: [15, 48, 12, 13, 10, 11]},
  {date: "2005-08-14", numbers: [34, 32, 1, 16, 37, 5]},
  {date: "2005-08-21", numbers: [9, 28, 23, 21, 16, 27]},
  {date: "2005-08-28", numbers: [23, 12, 14, 31, 16, 1]},
  {date: "2005-09-04", numbers: [13, 27, 34, 29, 15, 24]},
  {date: "2005-09-11", numbers: [2, 28, 1, 41, 20, 42]},
  {date: "2005-09-18", numbers: [12, 29, 6, 11, 40, 36]},
  {date: "2005-09-25", numbers: [45, 1, 11, 3, 27, 48]},
  {date: "2005-10-02", numbers: [11, 46, 7, 26, 8, 27]},
  {date: "2005-10-09", numbers: [36, 2, 37, 44, 41, 22]},
  {date: "2005-10-16", numbers: [36, 15, 43, 7, 38, 41]},
  {date: "2005-10-23", numbers: [16, 29, 28, 33, 6, 24]},
  {date: "2005-10-30", numbers: [43, 13, 29, 30, 23, 18]},
  {date: "2005-11-06", numbers: [24, 44, 41, 5, 49, 2]},
  {date: "2005-11-13", numbers: [35, 17, 18, 44, 26, 39]},
  {date: "2005-11-20", numbers: [24, 42, 13, 44, 2, 9]},
  {date: "2005-11-27", numbers: [35, 49, 33, 8, 3, 20]},
  {date: "2005-12-04", numbers: [14, 29, 41, 13, 46, 25]},
  {date: "2005-12-11", numbers: [20, 10, 4, 49, 22, 7]},
  {date: "2005-12-18", numbers: [15, 36, 37, 1, 47, 17]},
  {date: "2006-01-01", numbers: [15, 41, 35, 17, 7, 20]},
  {date: "2006-01-08", numbers: [49, 14, 33, 1, 42, 34]},
  {date: "2006-01-15", numbers: [13, 24, 43, 28, 22, 29]},
  {date: "2006-01-22", numbers: [3, 37, 34, 41, 43, 9]},
  {date: "2006-01-29", numbers: [12, 25, 49, 18, 11, 6]},
  {date: "2006-02-05", numbers: [25, 23, 15, 48, 37, 36]},
  {date: "2006-02-12", numbers: [35, 43, 20, 18, 39, 25]},
  {date: "2006-02-19", numbers: [36, 1, 31, 33, 43, 46]},
  {date: "2006-02-26", numbers: [43, 25, 49, 32, 18, 33]},
  {date: "2006-03-05", numbers: [34, 12, 43, 31, 5, 15]},
  {date: "2006-03-12", numbers: [31, 42, 46, 30, 14, 44]},
  {date: "2006-03-19", numbers: [30, 35, 3, 42, 6, 46]},
  {date: "2006-03-26", numbers: [15, 27, 19, 18, 11, 16]},
  {date: "2006-04-02", numbers: [7, 29, 3, 16, 21, 34]},
  {date: "2006-04-09", numbers: [34, 49, 18, 17, 41, 45]},
  {date: "2006-04-16", numbers: [27, 39, 10, 34, 19, 28]},
  {date: "2006-04-23", numbers: [18, 43, 30, 39, 31, 20]},
  {date: "2006-04-30", numbers: [37, 48, 28, 45, 11, 46]},
  {date: "2006-05-07", numbers: [8, 28, 41, 48, 26, 44]},
  {date: "2006-05-14", numbers: [12, 4, 45, 3, 43, 25]},
  {date: "2006-05-21", numbers: [34, 48, 29, 36, 13, 1]},
  {date: "2006-05-28", numbers: [2, 30, 29, 6, 23, 45]},
  {date: "2006-06-04", numbers: [27, 10, 19, 42, 46, 12]},
  {date: "2006-06-11", numbers: [20, 35, 13, 10, 41, 31]},
  {date: "2006-06-18", numbers: [4, 13, 2, 8, 24, 11]},
  {date: "2006-06-25", numbers: [44, 45, 10, 35, 39, 28]},
  {date: "2006-07-02", numbers: [39, 40, 41, 38, 30, 5]},
  {date: "2006-07-09", numbers: [34, 13, 32, 41, 18, 26]},
  {date: "2006-07-16", numbers: [1, 25, 36, 22, 10, 11]},
  {date: "2006-07-23", numbers: [5, 26, 21, 28, 48, 33]},
  {date: "2006-07-30", numbers: [40, 47, 8, 6, 29, 37]},
  {date: "2006-08-06", numbers: [26, 33, 23, 32, 18, 13]},
  {date: "2006-08-13", numbers: [2, 45, 20, 48, 21, 12]},
  {date: "2006-08-20", numbers: [44, 36, 2, 21, 9, 13]},
  {date: "2006-08-27", numbers: [16, 26, 31, 40, 37, 33]},
  {date: "2006-09-03", numbers: [20, 6, 22, 48, 23, 19]},
  {date: "2006-09-10", numbers: [20, 34, 1, 13, 30, 38]},
  {date: "2006-09-17", numbers: [4, 23, 21, 32, 3, 37]},
  {date: "2006-09-24", numbers: [29, 13, 36, 22, 16, 47]},
  {date: "2006-10-01", numbers: [13, 19, 49, 33, 39, 16]},
  {date: "2006-10-08", numbers: [24, 34, 16, 18, 41, 2]},
  {date: "2006-10-15", numbers: [35, 8, 46, 41, 5, 37]},
  {date: "2006-10-22", numbers: [1, 35, 47, 20, 25, 46]},
  {date: "2006-10-29", numbers: [20, 29, 34, 4, 27, 9]},
  {date: "2006-11-05", numbers: [25, 47, 42, 43, 29, 5]},
  {date: "2006-11-12", numbers: [15, 41, 22, 38, 18, 20]},
  {date: "2006-11-19", numbers: [3, 41, 35, 26, 34, 21]},
  {date: "2006-11-26", numbers: [17, 40, 28, 29, 1, 19]},
  {date: "2006-12-03", numbers: [2, 4, 3, 12, 29, 20]},
  {date: "2006-12-10", numbers: [39, 11, 48, 26, 33, 30]},
  {date: "2006-12-17", numbers: [46, 19, 16, 48, 12, 3]},
  {date: "2006-12-31", numbers: [46, 29, 8, 15, 36, 10]},
  {date: "2007-01-07", numbers: [31, 41, 37, 10, 46, 25]},
  {date: "2007-01-14", numbers: [39, 43, 21, 9, 7, 22]},
  {date: "2007-01-21", numbers: [27, 30, 47, 20, 48, 10]},
  {date: "2007-01-28", numbers: [49, 8, 26, 37, 2, 36]},
  {date: "2007-02-04", numbers: [28, 6, 33, 8, 42, 20]},
  {date: "2007-02-11", numbers: [13, 7, 1, 5, 11, 27]},
  {date: "2007-02-18", numbers: [5, 38, 35, 48, 32, 15]},
  {date: "2007-02-25", numbers: [5, 24, 13, 28, 42, 14]},
  {date: "2007-03-04", numbers: [15, 41, 29, 45, 17, 22]},
  {date: "2007-03-11", numbers: [41, 35, 13, 1, 20, 12]},
  {date: "2007-03-18", numbers: [5, 21, 18, 45, 30, 42]},
  {date: "2007-03-25", numbers: [42, 44, 17, 9, 23, 7]},
  {date: "2007-04-01", numbers: [44, 49, 43, 19, 10, 21]},
  {date: "2007-04-08", numbers: [10, 38, 40, 45, 26, 44]},
  {date: "2007-04-15", numbers: [35, 28, 6, 12, 15, 27]},
  {date: "2007-04-22", numbers: [6, 49, 16, 36, 35, 17]},
  {date: "2007-04-29", numbers: [31, 28, 36, 48, 15, 39]},
  {date: "2007-05-06", numbers: [41, 12, 22, 11, 30, 44]},
  {date: "2007-05-13", numbers: [16, 1, 44, 26, 4, 19]},
  {date: "2007-05-20", numbers: [36, 26, 42, 1, 30, 18]},
  {date: "2007-05-27", numbers: [17, 13, 21, 40, 25, 42]},
  {date: "2007-06-03", numbers: [16, 44, 5, 39, 14, 15]},
  {date: "2007-06-10", numbers: [48, 22, 18, 20, 34, 3]},
  {date: "2007-06-17", numbers: [25, 10, 26, 30, 5, 48]},
  {date: "2007-06-24", numbers: [28, 18, 11, 24, 31, 39]},
  {date: "2007-07-01", numbers: [29, 39, 15, 14, 23, 9]},
  {date: "2007-07-08", numbers: [8, 41, 46, 12, 4, 17]},
  {date: "2007-07-15", numbers: [36, 41, 44, 46, 48, 10]},
  {date: "2007-07-22", numbers: [24, 47, 46, 30, 9, 23]},
  {date: "2007-07-29", numbers: [43, 19, 13, 46, 38, 42]},
  {date: "2007-08-05", numbers: [44, 4, 38, 28, 37, 35]},
  {date: "2007-08-12", numbers: [42, 43, 18, 47, 46, 44]},
  {date: "2007-08-19", numbers: [46, 34, 45, 3, 19, 30]},
  {date: "2007-08-26", numbers: [46, 35, 42, 1, 2, 34]},
  {date: "2007-09-02", numbers: [45, 43, 44, 9, 11, 31]},
  {date: "2007-09-09", numbers: [1, 25, 22, 34, 11, 24]},
  {date: "2007-09-16", numbers: [34, 25, 41, 43, 9, 5]},
  {date: "2007-09-23", numbers: [45, 40, 28, 25, 46, 32]},
  {date: "2007-09-30", numbers: [25, 41, 39, 42, 20, 23]},
  {date: "2007-10-07", numbers: [2, 42, 31, 17, 48, 12]},
  {date: "2007-10-14", numbers: [30, 43, 32, 24, 17, 33]},
  {date: "2007-10-21", numbers: [27,  42, 21, 43, 11, 16]},
  {date: "2007-10-28", numbers: [47, 10,  15, 12, 44, 23]},
  {date: "2007-11-04", numbers: [28, 48, 12,  41, 3, 42]},
  {date: "2007-11-11", numbers: [26, 2, 47, 48,  5, 18]},
  {date: "2007-11-18", numbers: [33, 28, 21, 46, 19,  10]},
  {date: "2007-11-25", numbers: [15, 31, 40, 21, 33, 8]},
  {date: "2007-12-02", numbers: [4, 23, 6, 26, 9, 25]},
  {date: "2007-12-09", numbers: [14, 38, 46, 45, 15, 20]},
  {date: "2007-12-16", numbers: [16,  10, 41, 29, 40, 24]},
  {date: "2007-12-30", numbers: [4, 15,  42, 14, 12, 40]},
  {date: "2008-01-06", numbers: [26, 7, 48,  12, 24, 11]},
  {date: "2008-01-13", numbers: [33, 35, 23, 12,  19, 25]},
  {date: "2008-01-20", numbers: [29, 40, 45, 26, 4,  46]},
  {date: "2008-01-27", numbers: [36, 6, 47, 24, 20, 2]},
  {date: "2008-02-03", numbers: [29, 7, 34, 45, 13, 28]},
  {date: "2008-02-10", numbers: [25, 9, 27, 47, 2, 12]},
  {date: "2008-02-17", numbers: [11,  27, 35, 48, 42, 31]},
  {date: "2008-02-24", numbers: [32, 47,  42, 29, 26, 9]},
  {date: "2008-03-02", numbers: [9, 35, 39,  12, 46, 18]},
  {date: "2008-03-09", numbers: [21, 45, 29, 15,  26, 27]},
  {date: "2008-03-16", numbers: [27, 13, 16, 35, 45,  11]},
  {date: "2008-03-23", numbers: [23, 1, 30, 6, 20, 46]},
  {date: "2008-03-30", numbers: [42, 45, 43, 5, 31, 46]},
  {date: "2008-04-06", numbers: [5, 19, 25, 47, 45, 38]},
  {date: "2008-04-13", numbers: [16,  17, 5, 11, 26, 39]},
  {date: "2008-04-20", numbers: [20, 10,  38, 24, 5, 19]},
  {date: "2008-04-27", numbers: [19, 11, 36,  35, 43, 30]},
  {date: "2008-05-04", numbers: [35, 4, 30, 26,  47, 3]},
  {date: "2008-05-11", numbers: [45, 19, 16, 33, 38,  26]},
  {date: "2008-05-18", numbers: [28, 30, 9, 22, 41, 4]},
  {date: "2008-05-25", numbers: [4, 9, 3, 49, 39, 19]},
  {date: "2008-06-01", numbers: [22, 10, 31, 6, 23, 8]},
  {date: "2008-06-08", numbers: [17,  8, 32, 25, 6, 33]},
  {date: "2008-06-15", numbers: [32, 26,  5, 34, 19, 3]},
  {date: "2008-06-22", numbers: [35, 36, 25,  32, 10, 48]},
  {date: "2008-06-29", numbers: [41, 6, 17, 21,  12, 35]},
  {date: "2008-07-06", numbers: [5, 19, 2, 18, 7,  20]},
  {date: "2008-07-13", numbers: [13, 12, 3, 31, 2, 37]},
  {date: "2008-07-20", numbers: [7, 9, 43, 47, 42, 31]},
  {date: "2008-07-27", numbers: [9, 20, 33, 38, 41, 4]},
  {date: "2008-08-03", numbers: [8,  9, 48, 5, 18, 46]},
  {date: "2008-08-10", numbers: [7, 9,  37, 35, 8, 46]},
  {date: "2008-08-17", numbers: [35, 46, 34,  47, 41, 37]},
  {date: "2008-08-24", numbers: [3, 22, 21, 15,  10, 41]},
  {date: "2008-08-31", numbers: [38, 4, 26, 12, 20,  13]},
  {date: "2008-09-07", numbers: [27, 18, 2, 1, 39, 45]},
  {date: "2008-09-14", numbers: [5, 21, 34, 14, 11, 35]},
  {date: "2008-09-21", numbers: [38, 22, 14, 37, 19, 23]},
  {date: "2008-09-28", numbers: [16,  17, 24, 21, 48, 32]},
  {date: "2008-10-05", numbers: [13, 25,  8, 21, 37, 44]},
  {date: "2008-10-12", numbers: [1, 48, 24,  4, 33, 3]},
  {date: "2008-10-19", numbers: [34, 13, 33, 29,  7, 49]},
  {date: "2008-10-26", numbers: [18, 34, 32, 40, 49,  46]},
  {date: "2008-11-02", numbers: [6, 8, 37, 27, 39, 5]},
  {date: "2008-11-09", numbers: [10, 22, 2, 33, 45, 11]},
  {date: "2008-11-16", numbers: [12, 30, 36, 14, 4, 45]},
  {date: "2008-11-23", numbers: [5,  27, 6, 8, 30, 48]},
  {date: "2008-11-30", numbers: [36, 14,  4, 13, 1, 29]},
  {date: "2008-12-07", numbers: [41, 22, 13,  34, 3, 27]},
  {date: "2008-12-14", numbers: [49, 34, 7, 8,  33, 16]},
  {date: "2008-12-28", numbers: [36, 39, 22, 7, 8,  28]},
  {date: "2009-01-11", numbers: [21, 30, 11, 1, 22, 46]},
  {date: "2009-01-18", numbers: [37, 43, 41, 11, 20, 7]},
  {date: "2009-01-25", numbers: [14,  29, 39, 5, 4, 23]},
  {date: "2009-02-01", numbers: [44, 21,  7, 1, 41, 34]},
  {date: "2009-02-08", numbers: [33, 6, 25, 5,  31, 49]},
  {date: "2009-02-15", numbers: [15, 23, 9, 5, 25,  28]},
  {date: "2009-02-22", numbers: [3, 9, 20, 39, 15, 46]},
  {date: "2009-03-01", numbers: [41, 18, 11, 34, 30, 21]},
  {date: "2009-03-08", numbers: [48,  20, 26, 24, 1, 9]},
  {date: "2009-03-15", numbers: [2, 38,  29, 7, 48, 26]},
  {date: "2009-03-22", numbers: [14, 47, 21, 27,  39, 45]},
  {date: "2009-03-29", numbers: [44, 17, 7, 35, 38,  16]},
  {date: "2009-04-05", numbers: [37, 15, 22, 8, 36, 2]},
  {date: "2009-04-12", numbers: [32, 22, 3, 35, 29, 17]},
  {date: "2009-04-19", numbers: [38,  28, 1, 2, 36, 42]},
  {date: "2009-04-26", numbers: [27, 48,  19, 2, 39, 25]},
  {date: "2009-05-03", numbers: [25, 38, 34, 6,  4, 15]},
  {date: "2009-05-10", numbers: [38, 28, 13, 14, 6,  33]},
  {date: "2009-05-17", numbers: [11, 24, 46, 30, 38, 19]},
  {date: "2009-05-24", numbers: [3, 6, 27, 20, 29, 48]},
  {date: "2009-05-31", numbers: [12,  28, 36, 24, 38, 22]},
  {date: "2009-06-07", numbers: [15, 44,  19, 7, 37, 30]},
  {date: "2009-06-14", numbers: [3, 10, 24, 48,  13, 47]},
  {date: "2009-06-21", numbers: [25, 21, 46, 26, 28,  11]},
  {date: "2009-06-28", numbers: [31, 10, 16, 33, 38, 8]},
  {date: "2009-07-02", numbers: [9, 45, 10, 35, 43, 13]},
  {date: "2009-07-05", numbers: [44,  39, 18, 2, 41, 22]},
  {date: "2009-07-09", numbers: [3, 19,  39, 37, 6, 4]},
  {date: "2009-07-12", numbers: [25, 4, 6, 20,  22, 44]},
  {date: "2009-07-16", numbers: [42, 26, 44, 9, 32,  5]},
  {date: "2009-07-19", numbers: [49, 27, 42, 13, 21, 15]},
  {date: "2009-07-23", numbers: [40, 6, 3, 28, 2, 42]},
  {date: "2009-07-26", numbers: [36,  28, 41, 27, 24, 43]},
  {date: "2009-07-30", numbers: [43, 13,  38, 17, 28, 31]},
  {date: "2009-08-02", numbers: [30, 13, 46, 38,  8, 33]},
  {date: "2009-08-06", numbers: [49, 25, 17, 36, 6,  21]},
  {date: "2009-08-09", numbers: [11, 20, 45, 7, 26, 4]},
  {date: "2009-08-13", numbers: [2, 7, 39, 36, 9, 33]},
  {date: "2009-08-16", numbers: [44,  7, 5, 47, 23, 36]},
  {date: "2009-08-20", numbers: [36, 6,  42, 4, 8, 5]},
  {date: "2009-08-23", numbers: [16, 5, 18, 11,  4, 9]},
  {date: "2009-08-27", numbers: [36, 46, 39, 43, 41,  25]},
  {date: "2009-08-30", numbers: [28, 46, 45, 48, 30, 19]},
  {date: "2009-09-03", numbers: [32, 15, 3, 14, 39, 43]},
  {date: "2009-09-06", numbers: [2,  48, 30, 44, 26, 18]},
  {date: "2009-09-10", numbers: [40, 19,  5, 30, 3, 32]},
  {date: "2009-09-13", numbers: [43, 1, 33, 44,  34, 31]},
  {date: "2009-09-17", numbers: [27, 22, 1, 14, 28,  17]},
  {date: "2009-09-20", numbers: [41, 43, 16, 6, 22, 48]},
  {date: "2009-09-24", numbers: [22, 13, 6, 41, 17, 43]},
  {date: "2009-09-27", numbers: [3,  46, 5, 2, 1, 22]},
  {date: "2009-10-01", numbers: [25, 28,  48, 19, 30, 10]},
  {date: "2009-10-04", numbers: [45, 32, 1, 7,  23, 44]},
  {date: "2009-10-08", numbers: [39, 14, 31, 38, 12,  21]},
  {date: "2009-10-11", numbers: [10, 48, 9, 3, 27, 22]},
  {date: "2009-10-15", numbers: [32, 27, 33, 24, 22, 12]},
  {date: "2009-10-18", numbers: [37,  26, 15, 49, 6, 10]},
  {date: "2009-10-22", numbers: [10, 9,  22, 23, 25, 16]},
  {date: "2009-10-25", numbers: [12, 30, 39, 31,  7, 46]},
  {date: "2009-10-29", numbers: [1, 47, 33, 35, 14,  8]},
  {date: "2009-11-01", numbers: [7, 35, 25, 37, 31, 15]},
  {date: "2009-11-05", numbers: [41, 19, 40, 39, 5, 4]},
  {date: "2009-11-08", numbers: [36,  15, 8, 23, 13, 43]},
  {date: "2009-11-12", numbers: [6, 38,  45, 27, 15, 33]},
  {date: "2009-11-15", numbers: [43, 41, 37, 13,  29, 47]},
  {date: "2009-11-19", numbers: [25, 37, 5, 27, 17,  19]},
  {date: "2009-11-22", numbers: [4, 34, 49, 23, 40, 48]},
  {date: "2009-11-26", numbers: [41, 26, 38, 14, 13, 32]},
  {date: "2009-11-29", numbers: [32,  43, 4, 46, 12, 35]},
  {date: "2009-12-03", numbers: [13, 1,  10, 12, 19, 2]},
  {date: "2009-12-06", numbers: [4, 6, 13, 41,  2, 44]},
  {date: "2009-12-10", numbers: [4, 30, 35, 10, 1,  25]},
  {date: "2009-12-13", numbers: [9, 41, 37, 36, 39, 49]},
  {date: "2009-12-17", numbers: [4, 12, 18, 14, 22, 37]},
  {date: "2009-12-20", numbers: [47,  4, 28, 39, 43, 16]},
  {date: "2009-12-24", numbers: [34, 42,  8, 16, 13, 15]},
  {date: "2009-12-31", numbers: [27, 22, 35, 10,  48, 23]},
  {date: "2010-01-07", numbers: [46, 35, 40, 27, 7,  24]},
  {date: "2010-01-10", numbers: [21, 17, 45, 46, 18, 1]},
  {date: "2010-01-14", numbers: [7, 9, 47, 40, 31, 17]},
  {date: "2010-01-17", numbers: [37,  12, 38, 14, 23, 22]},
  {date: "2010-01-21", numbers: [22, 25,  27, 5, 1, 23]},
  {date: "2010-01-24", numbers: [27, 13, 34, 36,  25, 48]},
  {date: "2010-01-28", numbers: [7, 33, 45, 3, 11,  18]},
  {date: "2010-01-31", numbers: [37, 9, 35, 1, 42, 29]},
  {date: "2010-02-04", numbers: [14, 24, 33, 44, 5, 16]},
  {date: "2010-02-07", numbers: [47,  13, 28, 41, 32, 27]},
  {date: "2010-02-11", numbers: [7, 43,  27, 13, 17, 42]},
  {date: "2010-02-14", numbers: [34, 9, 28, 11,  31, 21]},
  {date: "2010-02-18", numbers: [24, 37, 19, 16, 32,  17]},
  {date: "2010-02-21", numbers: [6, 9, 40, 27, 24, 17]},
  {date: "2010-02-25", numbers: [13, 26, 5, 20, 47, 43]},
  {date: "2010-02-28", numbers: [17,  6, 5, 49, 21, 31]},
  {date: "2010-03-04", numbers: [8, 5,  20, 23, 16, 25]},
  {date: "2010-03-07", numbers: [46, 7, 12, 33,  15, 26]},
  {date: "2010-03-11", numbers: [6, 45, 17, 34, 33,  27]},
  {date: "2010-03-14", numbers: [2, 26, 5, 20, 12, 37]},
  {date: "2010-03-18", numbers: [21, 2, 24, 41, 1, 12]},
  {date: "2010-03-21", numbers: [29,  45, 25, 7, 2, 44]},
  {date: "2010-03-25", numbers: [19, 7,  1, 23, 44, 22]},
  {date: "2010-03-28", numbers: [4, 6, 8, 45,  48, 39]},
  {date: "2010-04-01", numbers: [15, 25, 7, 19, 9,  47]},
  {date: "2010-04-04", numbers: [27, 15, 34, 36, 7, 25]},
  {date: "2010-04-08", numbers: [45, 32, 36, 9, 48, 31]},
  {date: "2010-04-11", numbers: [49,  34, 33, 10, 48, 43]},
  {date: "2010-04-15", numbers: [46, 12,  36, 37, 35, 13]},
  {date: "2010-04-18", numbers: [14, 42, 37, 41,  47, 49]},
  {date: "2010-04-22", numbers: [44, 22, 8, 29, 1,  36]},
  {date: "2010-04-25", numbers: [40, 3, 13, 18, 16, 30]},
  {date: "2010-04-29", numbers: [19, 15, 43, 34, 26, 22]},
  {date: "2010-05-02", numbers: [14,  37, 48, 44, 11, 22]},
  {date: "2010-05-06", numbers: [23, 7,  15, 31, 43, 28]},
  {date: "2010-05-09", numbers: [2, 17, 1, 28, 21, 44]},
  {date: "2010-05-13", numbers: [37, 9, 5, 24, 12, 21]},
  {date: "2010-05-16", numbers: [48, 23, 19, 4, 29, 24]},
  {date: "2010-05-20", numbers: [47, 46, 3, 4, 33, 8]},
  {date: "2010-05-23", numbers: [13, 16, 24, 22, 25, 4]},
  {date: "2010-05-27", numbers: [34, 30, 49, 1, 15, 44]},
  {date: "2010-05-30", numbers: [32, 34, 7, 22, 37, 31]},
  {date: "2010-06-03", numbers: [13, 33, 16, 25, 1, 9]},
  {date: "2010-06-06", numbers: [20, 7, 1, 33, 19, 43]},
  {date: "2010-06-10", numbers: [46, 28, 17, 44, 20, 22]},
  {date: "2010-06-13", numbers: [3, 14, 10, 47, 2, 26]},
  {date: "2010-06-17", numbers: [42, 36, 15, 48, 35, 32]},
  {date: "2010-06-20", numbers: [20, 44, 45, 12, 19, 9]},
  {date: "2010-06-24", numbers: [44, 49, 47, 30, 12, 13]},
  {date: "2010-06-27", numbers: [11, 46, 10, 33, 5, 6]},
  {date: "2010-07-01", numbers: [36, 1, 34, 35, 27, 38]},
  {date: "2010-07-04", numbers: [12, 40, 30, 29, 9, 5]},
  {date: "2010-07-08", numbers: [6, 43, 11, 10, 7, 13]},
  {date: "2010-07-11", numbers: [12, 41, 31, 23, 48, 44]},
  {date: "2010-07-15", numbers: [26, 49, 34, 1, 12, 14]},
  {date: "2010-07-18", numbers: [10, 42, 43, 34, 45, 36]},
  {date: "2010-07-22", numbers: [29, 33, 6, 4, 42, 12]},
  {date: "2010-07-25", numbers: [14, 44, 40, 30, 22, 5]},
  {date: "2010-07-29", numbers: [23, 42, 49, 1, 41, 3]},
  {date: "2010-08-01", numbers: [10, 3, 31, 47, 28, 38]},
  {date: "2010-08-05", numbers: [14, 31, 16, 24, 1, 27]},
  {date: "2010-08-08", numbers: [49, 41, 3, 26, 7, 31]},
  {date: "2010-08-12", numbers: [14, 42, 37, 4, 33, 20]},
  {date: "2010-08-15", numbers: [40, 48, 34, 19, 21, 44]},
  {date: "2010-08-19", numbers: [38, 14, 23, 34, 17, 42]},
  {date: "2010-08-22", numbers: [43, 8, 32, 2, 1, 27]},
  {date: "2010-08-26", numbers: [6, 24, 20, 9, 38, 36]},
  {date: "2010-08-29", numbers: [28, 21, 12, 7, 23, 11]},
  {date: "2010-09-02", numbers: [15, 38, 45, 9, 29, 28]},
  {date: "2010-09-05", numbers: [25, 39, 37, 20, 38, 3]},
  {date: "2010-09-09", numbers: [49, 35, 44, 26, 7, 18]},
  {date: "2010-09-12", numbers: [13, 12, 33, 29, 5, 17]},
  {date: "2010-09-16", numbers: [5, 47, 41, 13, 12, 6]},
  {date: "2010-09-19", numbers: [10, 27, 37, 30, 16, 13]},
  {date: "2010-09-23", numbers: [15, 31, 30, 39, 7, 25]},
  {date: "2010-09-26", numbers: [2, 18, 1, 42, 17, 7]},
  {date: "2010-09-30", numbers: [40, 48, 4, 12, 30, 7]},
  {date: "2010-10-03", numbers: [38, 2, 5, 25, 27, 11]},
  {date: "2010-10-07", numbers: [39, 26, 38, 5, 36, 6]},
  {date: "2010-10-10", numbers: [1, 26, 12, 23, 44, 35]},
  {date: "2010-10-14", numbers: [13, 43, 6, 20, 3, 23]},
  {date: "2010-10-17", numbers: [29, 31, 37, 36, 16, 41]},
  {date: "2010-10-21", numbers: [31, 45, 8, 18, 38, 19]},
  {date: "2010-10-24", numbers: [6, 19, 40, 49, 45, 30]},
  {date: "2010-10-28", numbers: [5, 27, 43, 22, 20, 13]},
  {date: "2010-10-31", numbers: [46, 18, 33, 40, 7, 24]},
  {date: "2010-11-04", numbers: [19, 1, 22, 38, 18, 39]},
  {date: "2010-11-07", numbers: [20, 22, 32, 36, 33, 5]},
  {date: "2010-11-11", numbers: [5, 8, 45, 11, 22, 9]},
  {date: "2010-11-14", numbers: [19, 23, 12, 17, 4, 16]},
  {date: "2010-11-18", numbers: [29, 11, 15, 7, 5, 9]},
  {date: "2010-11-21", numbers: [32, 39, 38, 36, 27, 35]},
  {date: "2010-11-25", numbers: [11, 43, 2, 28, 48, 42]},
  {date: "2010-11-28", numbers: [32, 4, 3, 45, 36, 43]},
  {date: "2010-12-02", numbers: [4, 39, 23, 1, 5, 47]},
  {date: "2010-12-05", numbers: [39, 7, 21, 31, 4, 9]},
  {date: "2010-12-09", numbers: [25, 32, 48, 31, 47, 39]},
  {date: "2010-12-12", numbers: [13, 33, 37, 36, 34, 21]},
  {date: "2010-12-16", numbers: [33, 42, 43, 45, 1, 37]},
  {date: "2010-12-19", numbers: [15, 41, 21, 33, 49, 34]},
  {date: "2010-12-23", numbers: [25, 19, 24, 13, 14, 41]},
  {date: "2010-12-31_0", numbers: [8, 33, 36, 34, 9, 5]},
  {date: "2010-12-31_1", numbers: [42, 29, 37, 5, 43, 20]},
  {date: "2011-01-06", numbers: [38, 7, 17, 13, 25, 24]},
  {date: "2011-01-09", numbers: [14, 6, 13, 46, 37, 9]},
  {date: "2011-01-13", numbers: [41, 18, 47, 29, 45, 21]},
  {date: "2011-01-16", numbers: [18, 12, 7, 46, 4, 33]},
  {date: "2011-01-20", numbers: [45, 7, 9, 34, 25, 11]},
  {date: "2011-01-23", numbers: [36, 37, 17, 4, 12, 26]},
  {date: "2011-01-27", numbers: [29, 9, 5, 30, 27, 34]},
  {date: "2011-01-30", numbers: [12, 35, 29, 19, 1, 23]},
  {date: "2011-02-03", numbers: [31, 15, 4, 35, 18, 24]},
  {date: "2011-02-06", numbers: [47, 46, 34, 22, 26, 6]},
  {date: "2011-02-10", numbers: [37, 33, 29, 23, 14, 30]},
  {date: "2011-02-13", numbers: [31, 20, 39, 22, 13, 18]},
  {date: "2011-02-17", numbers: [33, 21, 24, 1, 28, 5]},
  {date: "2011-02-20", numbers: [9, 40, 18, 42, 16, 32]},
  {date: "2011-02-24", numbers: [8, 19, 46, 31, 15, 11]},
  {date: "2011-02-27", numbers: [8, 33, 24, 29, 7, 48]},
  {date: "2011-03-03", numbers: [9, 7, 26, 44, 39, 35]},
  {date: "2011-03-06", numbers: [7, 48, 40, 37, 19, 38]},
  {date: "2011-03-10", numbers: [20, 9, 48, 14, 12, 35]},
  {date: "2011-03-13", numbers: [39, 48, 1, 20, 23, 21]},
  {date: "2011-03-17", numbers: [18, 35, 6, 36, 3, 48]},
  {date: "2011-03-20", numbers: [29, 34, 24, 6, 14, 1]},
  {date: "2011-03-24", numbers: [28, 40, 27, 29, 30, 9]},
  {date: "2011-03-27", numbers: [29, 34, 3, 4, 5, 27]},
  {date: "2011-03-31", numbers: [1, 31, 27, 17, 4, 32]},
  {date: "2011-04-03", numbers: [27, 32, 18, 2, 24, 28]},
  {date: "2011-04-07", numbers: [4, 9, 37, 8, 35, 12]},
  {date: "2011-04-10", numbers: [45, 33, 24, 25, 29, 23]},
  {date: "2011-04-14", numbers: [23, 45, 2, 30, 37, 13]},
  {date: "2011-04-17", numbers: [16, 4, 17, 38, 46, 36]},
  {date: "2011-04-24_0", numbers: [36, 34, 5, 9, 6, 3]},
  {date: "2011-04-24_1", numbers: [40, 8, 39, 26, 15, 7]},
  {date: "2011-04-28", numbers: [12, 7, 40, 23, 4, 10]},
  {date: "2011-05-01", numbers: [27, 18, 40, 35, 32, 26]},
  {date: "2011-05-05", numbers: [11, 35, 4, 39, 40, 38]},
  {date: "2011-05-08", numbers: [18, 16, 20, 5, 38, 15]},
  {date: "2011-05-12", numbers: [39, 2, 1, 41, 11, 25]},
  {date: "2011-05-15", numbers: [28, 48, 33, 9, 12, 14]},
  {date: "2011-05-19", numbers: [23, 20, 13, 3, 9, 32]},
  {date: "2011-05-22", numbers: [48, 49, 14, 35, 39, 28]},
  {date: "2011-05-26", numbers: [1, 9, 7, 18, 2, 29]},
  {date: "2011-05-29", numbers: [13, 34, 2, 6, 11, 49]},
  {date: "2011-06-02", numbers: [10, 19, 32, 39, 35, 13]},
  {date: "2011-06-05", numbers: [4, 18, 8, 22, 15, 48]},
  {date: "2011-06-09", numbers: [34, 43, 32, 39, 17, 6]},
  {date: "2011-06-12", numbers: [13, 5, 41, 23, 16, 48]},
  {date: "2011-06-16", numbers: [41, 20, 2, 46, 17, 10]},
  {date: "2011-06-19", numbers: [40, 21, 3, 45, 49, 41]},
  {date: "2011-06-23", numbers: [49, 38, 32, 36, 10, 22]},
  {date: "2011-06-26", numbers: [42, 15, 10, 16, 44, 14]},
  {date: "2011-06-30", numbers: [44, 48, 19, 42, 49, 28]},
  {date: "2011-07-03", numbers: [41, 39, 8, 18, 1, 4]},
  {date: "2011-07-07", numbers: [33, 7, 20, 24, 17, 14]},
  {date: "2011-07-10", numbers: [15, 38, 46, 41, 32, 6]},
  {date: "2011-07-14", numbers: [32, 40, 31, 16, 12, 13]},
  {date: "2011-07-17", numbers: [15, 17, 37, 48, 28, 27]},
  {date: "2011-07-21", numbers: [10, 42, 2, 20, 35, 19]},
  {date: "2011-07-24", numbers: [18, 26, 45, 11, 32, 28]},
  {date: "2011-07-28", numbers: [2, 27, 15, 36, 35, 30]},
  {date: "2011-07-31", numbers: [15, 29, 34, 33, 22, 26]},
  {date: "2011-08-04", numbers: [9, 32, 16, 3, 11, 17]},
  {date: "2011-08-07", numbers: [43, 40, 4, 44, 6, 49]},
  {date: "2011-08-11", numbers: [42, 6, 36, 45, 37, 19]},
  {date: "2011-08-14", numbers: [27, 22, 32, 28, 15, 14]},
  {date: "2011-08-18", numbers: [8, 3, 11, 1, 10, 36]},
  {date: "2011-08-21", numbers: [25, 17, 10, 44, 45, 41]},
  {date: "2011-08-25", numbers: [33, 18, 41, 5, 38, 3]},
  {date: "2011-08-28", numbers: [10, 40, 43, 13, 46, 27]},
  {date: "2011-09-01", numbers: [21, 40, 12, 25, 35, 27]},
  {date: "2011-09-04", numbers: [44, 7, 14, 4, 20, 22]},
  {date: "2011-09-08", numbers: [11, 9, 26, 10, 30, 6]},
  {date: "2011-09-11", numbers: [43, 19, 29, 12, 38, 20]},
  {date: "2011-09-15", numbers: [2, 31, 33, 36, 1, 18]},
  {date: "2011-09-18", numbers: [26, 32, 29, 8, 27, 37]},
  {date: "2011-09-22", numbers: [31, 26, 32, 38, 45, 36]},
  {date: "2011-09-25", numbers: [16, 14, 19, 20, 25, 13]},
  {date: "2011-09-29", numbers: [28, 14, 16, 24, 23, 42]},
  {date: "2011-10-02", numbers: [39, 18, 12, 30, 25, 36]},
  {date: "2011-10-06", numbers: [49, 6, 14, 35, 24, 3]},
  {date: "2011-10-09", numbers: [40, 48, 8, 38, 43, 4]},
  {date: "2011-10-13", numbers: [42, 5, 46, 2, 43, 24]},
  {date: "2011-10-16", numbers: [38, 12, 3, 27, 24, 35]},
  {date: "2011-10-20", numbers: [43, 25, 31, 7, 17, 39]},
  {date: "2011-10-23", numbers: [25, 27, 28, 14, 22, 7]},
  {date: "2011-10-27", numbers: [20, 49, 21, 15, 19, 2]},
  {date: "2011-10-30", numbers: [27, 29, 36, 19, 44, 25]},
  {date: "2011-11-03", numbers: [45, 40, 30, 37, 43, 27]},
  {date: "2011-11-06", numbers: [2, 17, 33, 37, 34, 42]},
  {date: "2011-11-10", numbers: [11, 19, 13, 6, 24, 48]},
  {date: "2011-11-13", numbers: [34, 20, 12, 45, 9, 1]},
  {date: "2011-11-17", numbers: [19, 22, 45, 34, 48, 32]},
  {date: "2011-11-20", numbers: [47, 38, 31, 44, 18, 39]},
  {date: "2011-11-24", numbers: [48, 26, 36, 5, 25, 38]},
  {date: "2011-11-27", numbers: [44, 12, 26, 23, 45, 43]},
  {date: "2011-12-01", numbers: [25, 3, 14, 36, 46, 2]},
  {date: "2011-12-04", numbers: [47, 11, 26, 41, 28, 16]},
  {date: "2011-12-08", numbers: [47, 41, 22, 9, 33, 3]},
  {date: "2011-12-11", numbers: [41, 45, 37, 14, 8, 5]},
  {date: "2011-12-15", numbers: [15, 7, 8, 40, 33, 49]},
  {date: "2011-12-18", numbers: [7, 38, 30, 29, 28, 21]},
  {date: "2011-12-22", numbers: [42, 19, 22, 5, 45, 23]},
  {date: "2011-12-30_0", numbers: [17, 43, 37, 40, 29, 16]},
  {date: "2011-12-30_1", numbers: [20, 28, 38, 15, 48, 37]},
  {date: "2012-01-05", numbers: [9, 39, 23, 36, 3, 22]},
  {date: "2012-01-08", numbers: [49, 32, 19, 47, 42, 22]},
  {date: "2012-01-12", numbers: [15, 21, 4, 3, 5, 8]},
  {date: "2012-01-15", numbers: [14, 13, 39, 45, 4, 32]},
  {date: "2012-01-19", numbers: [9, 29, 31, 39, 3, 21]},
  {date: "2012-01-22", numbers: [13, 18, 31, 48, 24, 19]},
  {date: "2012-01-26", numbers: [23, 15, 4, 31, 35, 47]},
  {date: "2012-01-29", numbers: [20, 42, 9, 24, 12, 44]},
  {date: "2012-02-02", numbers: [36, 8, 34, 6, 5, 49]},
  {date: "2012-02-05", numbers: [14, 16, 33, 19, 17, 49]},
  {date: "2012-02-09", numbers: [38, 41, 45, 44, 8, 34]},
  {date: "2012-02-12", numbers: [47, 28, 40, 27, 9, 34]},
  {date: "2012-02-16", numbers: [39, 16, 9, 8, 18, 26]},
  {date: "2012-02-19", numbers: [16, 34, 3, 12, 28, 15]},
  {date: "2012-02-23", numbers: [10, 40, 42, 37, 26, 27]},
  {date: "2012-02-26", numbers: [10, 19, 1, 16, 18, 14]},
  {date: "2012-03-01", numbers: [49, 42, 34, 10, 19, 41]},
  {date: "2012-03-04", numbers: [49, 24, 44, 5, 22, 38]},
  {date: "2012-03-08", numbers: [10, 9, 14, 43, 25, 35]},
  {date: "2012-03-11", numbers: [25, 15, 43, 35, 36, 37]},
  {date: "2012-03-15", numbers: [10, 11, 2, 12, 49, 39]},
  {date: "2012-03-18", numbers: [3, 39, 33, 40, 2, 17]},
  {date: "2012-03-22", numbers: [20, 24, 13, 11, 31, 3]},
  {date: "2012-03-25", numbers: [43, 24, 36, 32, 12, 19]},
  {date: "2012-03-29", numbers: [5, 37, 11, 36, 6, 39]},
  {date: "2012-04-01", numbers: [7, 14, 20, 37, 43, 27]},
  {date: "2012-04-05", numbers: [33, 39, 23, 2, 12, 24]},
  {date: "2012-04-08", numbers: [4, 40, 13, 27, 29, 16]},
  {date: "2012-04-15_0", numbers: [38, 29, 16, 8, 28, 36]},
  {date: "2012-04-15_1", numbers: [39, 5, 46, 18, 32, 37]},
  {date: "2012-04-19", numbers: [1, 22, 18, 47, 26, 32]},
  {date: "2012-04-22", numbers: [46, 18, 43, 8, 20, 35]},
  {date: "2012-04-26", numbers: [45, 20, 35, 16, 43, 13]},
  {date: "2012-04-29", numbers: [27, 32, 34, 15, 21, 1]},
  {date: "2012-05-03", numbers: [33, 22, 4, 42, 17, 6]},
  {date: "2012-05-06", numbers: [47, 37, 17, 48, 7, 41]},
  {date: "2012-05-10", numbers: [48, 1, 46, 2, 32, 26]},
  {date: "2012-05-13", numbers: [17, 3, 26, 21, 29, 34]},
  {date: "2012-05-17", numbers: [9, 23, 41, 37, 43, 4]},
  {date: "2012-05-20", numbers: [43, 14, 42, 49, 4, 28]},
  {date: "2012-05-24", numbers: [11, 2, 9, 10, 38, 12]},
  {date: "2012-05-27", numbers: [29, 39, 33, 6, 5, 26]},
  {date: "2012-05-31", numbers: [42, 47, 23, 20, 25, 5]},
  {date: "2012-06-03", numbers: [46, 38, 23, 15, 18, 3]},
  {date: "2012-06-07", numbers: [17, 5, 16, 33, 32, 27]},
  {date: "2012-06-10", numbers: [25, 32, 20, 28, 6, 30]},
  {date: "2012-06-14", numbers: [12, 37, 9, 41, 15, 46]},
  {date: "2012-06-17", numbers: [43, 12, 2, 4, 8, 39]},
  {date: "2012-06-21", numbers: [2, 42, 45, 33, 41, 28]},
  {date: "2012-06-24", numbers: [20, 30, 28, 8, 5, 49]},
  {date: "2012-06-28", numbers: [11, 15, 19, 38, 21, 44]},
  {date: "2012-07-01", numbers: [33, 30, 10, 47, 5, 49]},
  {date: "2012-07-05", numbers: [21, 24, 43, 20, 13, 2]},
  {date: "2012-07-08", numbers: [26, 35, 38, 17, 5, 23]},
  {date: "2012-07-12", numbers: [32, 4, 40, 44, 47, 22]},
  {date: "2012-07-15", numbers: [32, 1, 45, 41, 44, 37]},
  {date: "2012-07-19", numbers: [30, 13, 12, 24, 15, 19]},
  {date: "2012-07-22", numbers: [48, 36, 24, 21, 47, 37]},
  {date: "2012-07-26", numbers: [1, 12, 28, 38, 16, 49]},
  {date: "2012-07-29", numbers: [7, 22, 9, 29, 2, 32]},
  {date: "2012-08-02", numbers: [13, 18, 47, 14, 15, 7]},
  {date: "2012-08-05", numbers: [28, 49, 20, 22, 8, 7]},
  {date: "2012-08-09", numbers: [13, 46, 36, 39, 21, 35]},
  {date: "2012-08-12", numbers: [25, 16, 18, 20, 23, 46]},
  {date: "2012-08-16", numbers: [38, 30, 11, 7, 5, 2]},
  {date: "2012-08-19", numbers: [33, 37, 31, 25, 28, 8]},
  {date: "2012-08-23", numbers: [21, 37, 34, 29, 48, 7]},
  {date: "2012-08-26", numbers: [23, 30, 39, 14, 40, 35]},
  {date: "2012-08-30", numbers: [25, 46, 4, 2, 24, 10]},
  {date: "2012-09-02", numbers: [21, 14, 26, 19, 3, 28]},
  {date: "2012-09-06", numbers: [2, 33, 38, 6, 36, 34]},
  {date: "2012-09-09", numbers: [37, 32, 30, 13, 18, 4]},
  {date: "2012-09-16_0", numbers: [9, 15, 25, 30, 17, 6]},
  {date: "2012-09-16_1", numbers: [22, 19, 12, 28, 29, 23]},
  {date: "2012-09-20", numbers: [15, 24, 46, 38, 33, 3]},
  {date: "2012-09-23", numbers: [14, 13, 17, 38, 21, 7]},
  {date: "2012-09-27", numbers: [13, 49, 8, 17, 28, 48]},
  {date: "2012-09-30", numbers: [6, 37, 34, 17, 9, 14]},
  {date: "2012-10-04", numbers: [16, 35, 21, 14, 34, 45]},
  {date: "2012-10-07", numbers: [28, 16, 19, 36, 46, 4]},
  {date: "2012-10-11", numbers: [18, 49, 20, 11, 30, 9]},
  {date: "2012-10-14", numbers: [43, 12, 24, 36, 7, 16]},
  {date: "2012-10-18", numbers: [42, 18, 41, 7, 35, 26]},
  {date: "2012-10-21", numbers: [9, 24, 2, 42, 25, 28]},
  {date: "2012-10-25", numbers: [22, 38, 29, 37, 13, 25]},
  {date: "2012-10-28", numbers: [37, 11, 47, 44, 31, 13]},
  {date: "2012-11-01", numbers: [8, 4, 48, 12, 29, 33]},
  {date: "2012-11-04", numbers: [37, 17, 32, 21, 26, 27]},
  {date: "2012-11-08", numbers: [16, 5, 40, 45, 14, 25]},
  {date: "2012-11-11", numbers: [44, 35, 34, 29, 36, 32]},
  {date: "2012-11-15", numbers: [12, 24, 8, 30, 4, 40]},
  {date: "2012-11-18", numbers: [22, 28, 48, 39, 20, 24]},
  {date: "2012-11-22", numbers: [7, 33, 26, 45, 38, 43]},
  {date: "2012-11-25", numbers: [12, 2, 28, 32, 7, 25]},
  {date: "2012-11-29", numbers: [18, 1, 37, 34, 43, 11]},
  {date: "2012-12-02", numbers: [10, 32, 37, 23, 45, 39]},
  {date: "2012-12-06", numbers: [47, 41, 23, 10, 21, 45]},
  {date: "2012-12-09", numbers: [40, 11, 47, 31, 38, 29]},
  {date: "2012-12-13", numbers: [42, 21, 24, 43, 44, 2]},
  {date: "2012-12-16", numbers: [38, 10, 3, 4, 6, 17]},
  {date: "2012-12-20", numbers: [14, 47, 24, 2, 13, 33]},
  {date: "2012-12-23", numbers: [23, 14, 39, 37, 46, 8]},
  {date: "2012-12-30_0", numbers: [49, 16, 29, 32, 48, 42]},
  {date: "2012-12-30_1", numbers: [6, 32, 36, 46, 8, 48]},
  {date: "2013-01-06", numbers: [49, 24, 10, 18, 27, 23]},
  {date: "2013-01-10", numbers: [39, 44, 27, 31, 5, 25]},
  {date: "2013-01-13", numbers: [47, 33, 10, 16, 27, 2]},
  {date: "2013-01-17", numbers: [4, 42, 46, 41, 26, 37]},
  {date: "2013-01-20", numbers: [6, 39, 42, 44, 49, 38]},
  {date: "2013-01-24", numbers: [36, 27, 48, 2, 6, 26]},
  {date: "2013-01-27", numbers: [40, 18, 25, 33, 39, 43]},
  {date: "2013-01-31", numbers: [40, 26, 32, 22, 23, 36]},
  {date: "2013-02-03", numbers: [45, 46, 1, 34, 8, 13]},
  {date: "2013-02-07", numbers: [20, 42, 39, 2, 25, 28]},
  {date: "2013-02-10", numbers: [3, 8, 38, 25, 1, 14]},
  {date: "2013-02-14", numbers: [3, 4, 46, 9, 44, 15]},
  {date: "2013-02-17", numbers: [2, 12, 19, 17, 45, 26]},
  {date: "2013-02-21", numbers: [27, 10, 7, 34, 32, 9]},
  {date: "2013-02-24", numbers: [1, 14, 35, 41, 7, 11]},
  {date: "2013-02-28", numbers: [15, 8, 21, 2, 18, 45]},
  {date: "2013-03-03", numbers: [48, 11, 31, 8, 43, 21]},
  {date: "2013-03-07", numbers: [17, 5, 15, 31, 42, 43]},
  {date: "2013-03-10", numbers: [32, 46, 9, 40, 17, 29]},
  {date: "2013-03-14", numbers: [22, 13, 24, 3, 5, 34]},
  {date: "2013-03-17", numbers: [27, 28, 11, 8, 12, 14]},
  {date: "2013-03-21", numbers: [14, 2, 23, 20, 47, 49]},
  {date: "2013-03-24", numbers: [16, 5, 33, 4, 36, 20]},
  {date: "2013-03-28", numbers: [4, 5, 32, 10, 22, 46]},
  {date: "2013-03-31", numbers: [2, 7, 46, 4, 6, 16]},
  {date: "2013-04-04", numbers: [6, 49, 24, 16, 5, 9]},
  {date: "2013-04-07", numbers: [21, 35, 43, 5, 7, 46]},
  {date: "2013-04-11", numbers: [23, 10, 25, 13, 44, 39]},
  {date: "2013-04-14", numbers: [13, 46, 31, 35, 30, 12]},
  {date: "2013-04-18", numbers: [19, 16, 21, 39, 7, 28]},
  {date: "2013-04-21", numbers: [47, 38, 10, 25, 20, 15]},
  {date: "2013-04-25", numbers: [37, 34, 26, 18, 40, 11]},
  {date: "2013-04-28", numbers: [12, 36, 24, 16, 43, 10]},
  {date: "2013-05-05_0", numbers: [41, 2, 27, 28, 30, 42]},
  {date: "2013-05-05_1", numbers: [6, 48, 34, 12, 22, 38]},
  {date: "2013-05-09", numbers: [38, 28, 6, 2, 22, 23]},
  {date: "2013-05-12", numbers: [9, 29, 45, 5, 3, 11]},
  {date: "2013-05-16", numbers: [35, 31, 7, 27, 26, 19]},
  {date: "2013-05-19", numbers: [48, 21, 24, 4, 39, 1]},
  {date: "2013-05-23", numbers: [47, 8, 6, 15, 11, 26]},
  {date: "2013-05-26", numbers: [4, 40, 34, 17, 42, 49]},
  {date: "2013-05-30", numbers: [7, 11, 12, 10, 46, 37]},
  {date: "2013-06-02", numbers: [6, 34, 36, 9, 26, 16]},
  {date: "2013-06-06", numbers: [6, 38, 47, 34, 32, 14]},
  {date: "2013-06-09", numbers: [24, 44, 37, 45, 8, 41]},
  {date: "2013-06-13", numbers: [44, 20, 48, 49, 1, 25]},
  {date: "2013-06-16", numbers: [17, 9, 44, 21, 32, 35]},
  {date: "2013-06-20", numbers: [41, 8, 38, 21, 9, 36]},
  {date: "2013-06-23", numbers: [32, 39, 17, 19, 5, 47]},
  {date: "2013-06-27", numbers: [2, 41, 29, 43, 44, 48]},
  {date: "2013-06-30", numbers: [16, 25, 14, 36, 8, 33]},
  {date: "2013-07-04", numbers: [29, 38, 44, 27, 18, 17]},
  {date: "2013-07-07", numbers: [11, 2, 34, 36, 48, 8]},
  {date: "2013-07-11", numbers: [27, 23, 37, 4, 30, 8]},
  {date: "2013-07-14", numbers: [22, 49, 18, 7, 47, 6]},
  {date: "2013-07-18", numbers: [38, 20, 25, 40, 4, 3]},
  {date: "2013-07-21", numbers: [22, 29, 11, 9, 2, 39]},
  {date: "2013-07-25", numbers: [15, 7, 6, 24, 34, 28]},
  {date: "2013-07-28", numbers: [41, 11, 9, 45, 42, 19]},
  {date: "2013-08-01", numbers: [41, 12, 46, 15, 40, 37]},
  {date: "2013-08-04", numbers: [7, 4, 10, 32, 46, 45]},
  {date: "2013-08-08", numbers: [17, 5, 25, 20, 43, 23]},
  {date: "2013-08-11", numbers: [13, 21, 49, 3, 40, 43]},
  {date: "2013-08-15", numbers: [23, 46, 18, 17, 47, 15]},
  {date: "2013-08-18", numbers: [43, 23, 25, 3, 22, 17]},
  {date: "2013-08-22", numbers: [15, 32, 21, 7, 23, 40]},
  {date: "2013-08-25", numbers: [28, 30, 49, 29, 34, 25]},
  {date: "2013-08-29", numbers: [8, 5, 36, 3, 2, 41]},
  {date: "2013-09-01", numbers: [26, 44, 7, 16, 25, 11]},
  {date: "2013-09-05", numbers: [41, 10, 28, 36, 27, 16]},
  {date: "2013-09-08", numbers: [6, 26, 13, 24, 47, 34]},
  {date: "2013-09-15_0", numbers: [44, 2, 24, 15, 48, 33]},
  {date: "2013-09-15_1", numbers: [15, 39, 44, 36, 19, 33]},
  {date: "2013-09-19", numbers: [33, 32, 37, 41, 8, 45]},
  {date: "2013-09-22", numbers: [4, 46, 28, 30, 49, 17]},
  {date: "2013-09-26", numbers: [14, 49, 36, 11, 35, 8]},
  {date: "2013-09-29", numbers: [7, 31, 35, 12, 48, 44]},
  {date: "2013-10-03", numbers: [21, 41, 15, 1, 37, 34]},
  {date: "2013-10-06", numbers: [25, 36, 29, 49, 47, 31]},
  {date: "2013-10-10", numbers: [35, 10, 3, 33, 48, 34]},
  {date: "2013-10-13", numbers: [17, 13, 35, 9, 30, 39]},
  {date: "2013-10-17", numbers: [34, 26, 2, 16, 36, 43]},
  {date: "2013-10-20", numbers: [40, 19, 11, 37, 20, 6]},
  {date: "2013-10-24", numbers: [32, 1, 49, 40, 30, 20]},
  {date: "2013-10-27", numbers: [41, 36, 10, 20, 17, 12]},
  {date: "2013-10-31", numbers: [37, 45, 4, 25, 5, 6]},
  {date: "2013-11-03", numbers: [39, 32, 49, 24, 25, 15]},
  {date: "2013-11-07", numbers: [48, 17, 49, 19, 32, 29]},
  {date: "2013-11-10", numbers: [25, 46, 15, 4, 7, 22]},
  {date: "2013-11-14", numbers: [43, 16, 6, 29, 25, 37]},
  {date: "2013-11-17", numbers: [36, 44, 28, 23, 25, 21]},
  {date: "2013-11-21", numbers: [13, 37, 32, 21, 7, 33]},
  {date: "2013-11-24", numbers: [1, 40, 42, 47, 25, 35]},
  {date: "2013-11-28", numbers: [46, 26, 43, 30, 38, 21]},
  {date: "2013-12-01", numbers: [26, 48, 8, 2, 39, 25]},
  {date: "2013-12-05", numbers: [46, 6, 20, 45, 32, 24]},
  {date: "2013-12-08", numbers: [32, 40, 22, 14, 9, 18]},
  {date: "2013-12-12", numbers: [17, 32, 49, 37, 35, 41]},
  {date: "2013-12-15", numbers: [8, 34, 9, 35, 18, 40]},
  {date: "2013-12-19", numbers: [12, 10, 1, 3, 47, 35]},
  {date: "2013-12-22", numbers: [3, 45, 22, 48, 10, 13]},
  {date: "2013-12-30_0", numbers: [28, 21, 17, 23, 4, 48]},
  {date: "2013-12-30_1", numbers: [11, 34, 30, 3, 9, 5]},
  {date: "2014-01-05", numbers: [33, 45, 25, 10, 4, 48]},
  {date: "2014-01-09", numbers: [2, 33, 26, 24, 12, 16]},
  {date: "2014-01-12", numbers: [43, 24, 39, 4, 32, 7]},
  {date: "2014-01-16", numbers: [1, 45, 43, 48, 9, 21]},
  {date: "2014-01-19", numbers: [34, 29, 48, 30, 4, 27]},
  {date: "2014-01-23", numbers: [28, 29, 18, 4, 17, 16]},
  {date: "2014-01-26", numbers: [29, 38, 6, 25, 8, 34]},
  {date: "2014-01-30", numbers: [6, 37, 29, 32, 18, 42]},
  {date: "2014-02-02", numbers: [13, 37, 25, 12, 30, 46]},
  {date: "2014-02-06", numbers: [2, 14, 12, 3, 25, 17]},
  {date: "2014-02-09", numbers: [1, 9, 17, 20, 22, 49]},
  {date: "2014-02-13", numbers: [48, 14, 12, 1, 17, 23]},
  {date: "2014-02-16", numbers: [4, 49, 29, 23, 31, 1]},
  {date: "2014-02-20", numbers: [10, 12, 3, 48, 36, 4]},
  {date: "2014-02-23", numbers: [44, 16, 27, 33, 31, 11]},
  {date: "2014-02-27", numbers: [30, 34, 38, 4, 6, 27]},
  {date: "2014-03-02", numbers: [24, 21, 13, 37, 39, 6]},
  {date: "2014-03-06", numbers: [45, 36, 11, 44, 42, 16]},
  {date: "2014-03-09", numbers: [44, 11, 22, 5, 15, 47]},
  {date: "2014-03-13", numbers: [1, 46, 25, 27, 17, 36]},
  {date: "2014-03-16", numbers: [36, 8, 22, 31, 44, 12]},
  {date: "2014-03-20", numbers: [31, 6, 34, 4, 44, 49]},
  {date: "2014-03-23", numbers: [23, 2, 9, 18, 33, 32]},
  {date: "2014-03-27", numbers: [5, 23, 13, 9, 2, 4]},
  {date: "2014-03-30", numbers: [45, 33, 40, 21, 35, 5]},
  {date: "2014-04-03", numbers: [10, 38, 5, 7, 14, 45]},
  {date: "2014-04-06", numbers: [14, 6, 33, 11, 5, 49]},
  {date: "2014-04-10", numbers: [46, 49, 13, 8, 43, 47]},
  {date: "2014-04-13", numbers: [15, 48, 5, 8, 30, 1]},
  {date: "2014-04-20_0", numbers: [3, 45, 26, 40, 47, 32]},
  {date: "2014-04-20_1", numbers: [5, 3, 44, 26, 42, 9]},
  {date: "2014-04-24", numbers: [18, 46, 11, 41, 5, 10]},
  {date: "2014-04-27", numbers: [28, 38, 23, 15, 35, 3]},
  {date: "2014-05-01", numbers: [1, 25, 20, 17, 22, 4]},
  {date: "2014-05-04", numbers: [44, 49, 20, 30, 15, 6]},
  {date: "2014-05-08", numbers: [41, 14, 48, 8, 6, 49]},
  {date: "2014-05-11", numbers: [37, 43, 6, 46, 17, 7]},
  {date: "2014-05-15", numbers: [35, 39, 19, 47, 43, 20]},
  {date: "2014-05-18", numbers: [6, 19, 47, 9, 31, 36]},
  {date: "2014-05-22", numbers: [13, 33, 40, 9, 12, 36]},
  {date: "2014-05-25", numbers: [43, 33, 3, 44, 29, 37]},
  {date: "2014-05-29", numbers: [31, 8, 2, 6, 10, 19]},
  {date: "2014-06-01", numbers: [31, 44, 35, 32, 7, 43]},
  {date: "2014-06-05", numbers: [8, 31, 37, 49, 2, 6]},
  {date: "2014-06-08", numbers: [2, 22, 30, 5, 41, 27]},
  {date: "2014-06-12", numbers: [49, 21, 17, 32, 34, 11]},
  {date: "2014-06-15", numbers: [17, 23, 43, 1, 28, 47]},
  {date: "2014-06-19", numbers: [28, 40, 19, 4, 41, 14]},
  {date: "2014-06-22", numbers: [24, 11, 14, 44, 41, 16]},
  {date: "2014-06-26", numbers: [25, 10, 44, 27, 31, 11]},
  {date: "2014-06-29", numbers: [35, 18, 12, 2, 17, 7]},
  {date: "2014-07-03", numbers: [38, 28, 31, 15, 6, 32]},
  {date: "2014-07-06", numbers: [39, 11, 21, 13, 47, 30]},
  {date: "2014-07-10", numbers: [30, 34, 11, 40, 33, 19]},
  {date: "2014-07-13", numbers: [13, 26, 33, 27, 31, 21]},
  {date: "2014-07-17", numbers: [44, 25, 2, 29, 46, 45]},
  {date: "2014-07-20", numbers: [5, 12, 38, 2, 14, 48]},
  {date: "2014-07-24", numbers: [20, 34, 15, 7, 3, 17]},
  {date: "2014-07-27", numbers: [48, 11, 45, 12, 30, 32]},
  {date: "2014-07-31", numbers: [33, 28, 19, 9, 17, 10]},
  {date: "2014-08-03", numbers: [4, 29, 47, 1, 30, 43]},
  {date: "2014-08-07", numbers: [23, 3, 13, 25, 19, 32]},
  {date: "2014-08-10", numbers: [31, 37, 27, 47, 22, 45]},
  {date: "2014-08-14", numbers: [8, 37, 13, 25, 36, 21]},
  {date: "2014-08-17", numbers: [15, 1, 35, 17, 11, 21]},
  {date: "2014-08-21", numbers: [40, 32, 45, 24, 48, 26]},
  {date: "2014-08-24", numbers: [21, 45, 19, 38, 27, 18]},
  {date: "2014-08-28", numbers: [21, 34, 4, 49, 9, 46]},
  {date: "2014-08-31", numbers: [40, 33, 29, 27, 13, 39]},
  {date: "2014-09-04", numbers: [8, 22, 48, 27, 21, 16]},
  {date: "2014-09-07", numbers: [28, 3, 22, 45, 39, 26]},
  {date: "2014-09-14_0", numbers: [28, 11, 14, 29, 13, 33]},
  {date: "2014-09-14_1", numbers: [19, 44, 22, 23, 12, 32]},
  {date: "2014-09-18", numbers: [26, 41, 4, 46, 30, 16]},
  {date: "2014-09-21", numbers: [19, 42, 1, 32, 33, 47]},
  {date: "2014-09-25", numbers: [25, 23, 38, 46, 27, 5]},
  {date: "2014-09-28", numbers: [11, 24, 19, 4, 41, 20]},
  {date: "2014-10-02", numbers: [15, 49, 28, 25, 23, 47]},
  {date: "2014-10-05", numbers: [12, 22, 15, 31, 25, 32]},
  {date: "2014-10-09", numbers: [41, 5, 48, 43, 21, 32]},
  {date: "2014-10-12", numbers: [23, 49, 17, 45, 32, 24]},
  {date: "2014-10-16", numbers: [29, 17, 1, 46, 25, 31]},
  {date: "2014-10-19", numbers: [13, 19, 25, 1, 15, 6]},
  {date: "2014-10-23", numbers: [28, 47, 38, 31, 9, 40]},
  {date: "2014-10-26", numbers: [33, 26, 49, 22, 12, 2]},
  {date: "2014-10-30", numbers: [45, 5, 39, 30, 24, 46]},
  {date: "2014-11-02", numbers: [42, 39, 48, 33, 24, 41]},
  {date: "2014-11-06", numbers: [20, 11, 16, 36, 15, 49]},
  {date: "2014-11-09", numbers: [4, 36, 6, 46, 32, 44]},
  {date: "2014-11-13", numbers: [30, 42, 23, 16, 8, 39]},
  {date: "2014-11-16", numbers: [30, 22, 17, 6, 18, 28]},
  {date: "2014-11-20", numbers: [40, 36, 19, 2, 18, 12]},
  {date: "2014-11-23", numbers: [2, 11, 32, 34, 38, 28]},
  {date: "2014-11-27", numbers: [23, 22, 36, 6, 20, 33]},
  {date: "2014-11-30", numbers: [12, 34, 17, 16, 20, 15]},
  {date: "2014-12-04", numbers: [46, 29, 35, 44, 2, 4]},
  {date: "2014-12-07", numbers: [47, 6, 28, 36, 1, 20]},
  {date: "2014-12-11", numbers: [34, 35, 1, 5, 36, 47]},
  {date: "2014-12-14", numbers: [17, 49, 22, 10, 45, 33]},
  {date: "2014-12-18", numbers: [2, 24, 39, 16, 4, 10]},
  {date: "2014-12-21", numbers: [40, 28, 5, 12, 19, 44]},
  {date: "2014-12-30_0", numbers: [39, 36, 47, 6, 1, 3]},
  {date: "2014-12-30_1", numbers: [22, 16, 47, 34, 48, 20]},
  {date: "2015-01-11", numbers: [45, 16, 3, 9, 34, 13]},
  {date: "2015-01-15", numbers: [25, 23, 45, 18, 11, 36]},
  {date: "2015-01-18", numbers: [35, 1, 15, 27, 3, 14]},
  {date: "2015-01-22", numbers: [41, 36, 4, 15, 32, 20]},
  {date: "2015-01-25", numbers: [35, 5, 16, 25, 36, 9]},
  {date: "2015-01-29", numbers: [13, 28, 46, 35, 44, 39]},
  {date: "2015-02-01", numbers: [10, 27, 2, 41, 45, 37]},
  {date: "2015-02-05", numbers: [24, 8, 3, 26, 21, 25]},
  {date: "2015-02-08", numbers: [19, 29, 5, 34, 41, 2]},
  {date: "2015-02-12", numbers: [9, 25, 5, 35, 40, 33]},
  {date: "2015-02-15", numbers: [25, 28, 9, 45, 34, 43]},
  {date: "2015-02-19", numbers: [43, 42, 18, 2, 16, 15]},
  {date: "2015-02-22", numbers: [40, 18, 7, 29, 33, 14]},
  {date: "2015-02-26", numbers: [46, 25, 42, 23, 26, 16]},
  {date: "2015-03-01_0", numbers: [9, 46, 34, 11, 18, 35]},
  {date: "2015-03-01_1", numbers: [21, 15, 27, 37, 29, 14]},
  {date: "2015-03-05", numbers: [8, 48, 22, 2, 41, 27]},
  {date: "2015-03-08", numbers: [3, 8, 11, 26, 48, 46]},
  {date: "2015-03-12", numbers: [16, 1, 47, 25, 26, 15]},
  {date: "2015-03-15", numbers: [43, 17, 34, 35, 47, 3]},
  {date: "2015-03-19", numbers: [6, 23, 7, 30, 21, 46]},
  {date: "2015-03-22", numbers: [46, 36, 45, 7, 35, 13]},
  {date: "2015-03-26", numbers: [2, 45, 27, 4, 38, 48]},
  {date: "2015-03-29", numbers: [22, 10, 4, 6, 18, 9]},
  {date: "2015-04-02", numbers: [11, 38, 36, 1, 27, 16]},
  {date: "2015-04-05", numbers: [33, 5, 30, 44, 4, 27]},
  {date: "2015-04-09", numbers: [18, 36, 32, 49, 29, 6]},
  {date: "2015-04-12_0", numbers: [15, 44, 16, 19, 31, 23]},
  {date: "2015-04-12_1", numbers: [18, 25, 45, 23, 27, 40]},
  {date: "2015-04-16", numbers: [26, 42, 45, 8, 1, 5]},
  {date: "2015-04-19", numbers: [32, 44, 21, 38, 35, 16]},
  {date: "2015-04-23", numbers: [1, 18, 10, 30, 17, 44]},
  {date: "2015-04-26", numbers: [46, 29, 30, 1, 10, 2]},
  {date: "2015-04-30", numbers: [43, 19, 27, 11, 14, 46]},
  {date: "2015-05-03", numbers: [45, 8, 5, 37, 19, 1]},
  {date: "2015-05-07", numbers: [3, 48, 14, 47, 9, 16]},
  {date: "2015-05-10", numbers: [4, 25, 6, 17, 48, 14]},
  {date: "2015-05-14", numbers: [30, 21, 38, 33, 8, 11]},
  {date: "2015-05-17", numbers: [16, 19, 48, 25, 32, 10]},
  {date: "2015-05-21", numbers: [34, 1, 17, 10, 39, 18]},
  {date: "2015-05-24", numbers: [32, 25, 45, 43, 40, 2]},
  {date: "2015-05-28", numbers: [46, 41, 12, 44, 39, 22]},
  {date: "2015-05-31", numbers: [14, 35, 29, 8, 23, 21]},
  {date: "2015-06-04", numbers: [24, 38, 26, 28, 7, 17]},
  {date: "2015-06-07", numbers: [47, 30, 45, 37, 12, 17]},
  {date: "2015-06-11", numbers: [32, 17, 46, 5, 48, 33]},
  {date: "2015-06-14", numbers: [28, 23, 24, 45, 10, 37]},
  {date: "2015-06-18", numbers: [38, 37, 1, 33, 41, 15]},
  {date: "2015-06-21", numbers: [11, 34, 1, 7, 37, 18]},
  {date: "2015-06-25", numbers: [18, 37, 8, 43, 26, 45]},
  {date: "2015-06-28", numbers: [38, 6, 36, 22, 48, 47]},
  {date: "2015-07-02", numbers: [36, 30, 20, 4, 5, 10]},
  {date: "2015-07-05", numbers: [27, 4, 47, 15, 10, 41]},
  {date: "2015-07-09", numbers: [19, 17, 5, 2, 41, 28]},
  {date: "2015-07-12", numbers: [45, 13, 15, 32, 2, 23]},
  {date: "2015-07-16", numbers: [43, 48, 31, 33, 24, 45]},
  {date: "2015-07-19", numbers: [37, 33, 10, 43, 49, 40]},
  {date: "2015-07-23", numbers: [16, 48, 46, 36, 42, 26]},
  {date: "2015-07-26", numbers: [48, 43, 38, 6, 10, 36]},
  {date: "2015-07-30", numbers: [34, 25, 44, 18, 39, 24]},
  {date: "2015-08-02", numbers: [1, 27, 14, 23, 30, 40]},
  {date: "2015-08-06", numbers: [35, 16, 14, 7, 46, 40]},
  {date: "2015-08-09", numbers: [29, 15, 21, 46, 36, 18]},
  {date: "2015-08-13", numbers: [16, 10, 14, 24, 3, 6]},
  {date: "2015-08-16", numbers: [11, 8, 44, 10, 46, 35]},
  {date: "2015-08-20", numbers: [26, 41, 38, 18, 4, 36]},
  {date: "2015-08-23", numbers: [16, 28, 17, 43, 20, 36]},
  {date: "2015-08-27", numbers: [23, 5, 26, 42, 17, 34]},
  {date: "2015-08-30", numbers: [44, 48, 1, 34, 2, 25]},
  {date: "2015-09-03", numbers: [15, 10, 26, 9, 6, 47]},
  {date: "2015-09-06", numbers: [38, 3, 12, 16, 28, 18]},
  {date: "2015-09-13_0", numbers: [28, 40, 3, 17, 34, 49]},
  {date: "2015-09-13_1", numbers: [22, 21, 18, 17, 41, 8]},
  {date: "2015-09-17", numbers: [32, 3, 28, 14, 8, 29]},
  {date: "2015-09-20", numbers: [48, 34, 20, 27, 47, 2]},
  {date: "2015-09-24", numbers: [49, 31, 38, 44, 14, 10]},
  {date: "2015-09-27", numbers: [26, 1, 32, 18, 12, 7]},
  {date: "2015-10-01", numbers: [11, 2, 38, 32, 41, 30]},
  {date: "2015-10-04", numbers: [31, 1, 36, 39, 24, 14]},
  {date: "2015-10-08", numbers: [33, 18, 6, 42, 37, 35]},
  {date: "2015-10-11", numbers: [30, 44, 27, 29, 28, 45]},
  {date: "2015-10-15", numbers: [14, 45, 46, 9, 22, 8]},
  {date: "2015-10-18", numbers: [10, 17, 47, 11, 12, 23]},
  {date: "2015-10-22", numbers: [44, 3, 24, 1, 49, 2]},
  {date: "2015-10-25", numbers: [31, 9, 7, 17, 10, 47]},
  {date: "2015-10-29", numbers: [2, 12, 26, 19, 37, 25]},
  {date: "2015-11-01", numbers: [11, 21, 20, 8, 41, 34]},
  {date: "2015-11-05", numbers: [45, 41, 47, 42, 40, 10]},
  {date: "2015-11-08", numbers: [13, 16, 32, 7, 10, 36]},
  {date: "2015-11-12", numbers: [44, 2, 13, 39, 12, 19]},
  {date: "2015-11-15", numbers: [29, 10, 2, 30, 3, 18]},
  {date: "2015-11-19", numbers: [31, 3, 10, 47, 25, 8]},
  {date: "2015-11-22", numbers: [14, 43, 23, 21, 33, 16]},
  {date: "2015-11-26", numbers: [21, 46, 26, 32, 3, 35]},
  {date: "2015-11-29", numbers: [5, 28, 26, 35, 12, 47]},
  {date: "2015-12-03", numbers: [10, 13, 37, 44, 14, 46]},
  {date: "2015-12-06", numbers: [26, 49, 9, 20, 48, 6]},
  {date: "2015-12-10", numbers: [41, 1, 20, 46, 36, 14]},
  {date: "2015-12-13", numbers: [32, 25, 11, 21, 14, 38]},
  {date: "2015-12-17", numbers: [46, 26, 11, 19, 28, 6]},
  {date: "2015-12-20", numbers: [8, 18, 44, 27, 2, 13]},
  {date: "2015-12-30_0", numbers: [10, 15, 18, 47, 34, 48]},
  {date: "2015-12-30_1", numbers: [8, 35, 4, 28, 40, 22]},
  {date: "2016-01-07", numbers: [34, 26, 36, 29, 11, 25]},
  {date: "2016-01-10", numbers: [41, 16, 14, 8, 29, 47]},
  {date: "2016-01-14", numbers: [45, 41, 38, 46, 23, 33]},
  {date: "2016-01-17", numbers: [21, 20, 9, 5, 2, 14]},
  {date: "2016-01-21", numbers: [17, 13, 5, 1, 20, 9]},
  {date: "2016-01-24", numbers: [43, 39, 4, 28, 20, 5]},
  {date: "2016-01-28", numbers: [11, 35, 13, 1, 33, 14]},
  {date: "2016-01-31", numbers: [28, 6, 49, 18, 9, 36]},
  {date: "2016-02-04", numbers: [42, 47, 44, 45, 6, 24]},
  {date: "2016-02-07", numbers: [36, 29, 4, 1, 45, 42]},
  {date: "2016-02-11", numbers: [32, 40, 9, 31, 21, 28]},
  {date: "2016-02-14", numbers: [23, 46, 8, 11, 48, 13]},
  {date: "2016-02-18", numbers: [33, 5, 4, 18, 16, 8]},
  {date: "2016-02-21", numbers: [47, 21, 1, 40, 31, 39]},
  {date: "2016-02-25", numbers: [15, 34, 31, 37, 6, 24]},
  {date: "2016-02-28", numbers: [25, 4, 19, 41, 20, 36]},
  {date: "2016-03-03", numbers: [16, 38, 19, 27, 41, 45]},
  {date: "2016-03-06_0", numbers: [29, 8, 2, 34, 46, 37]},
  {date: "2016-03-06_1", numbers: [41, 14, 49, 25, 2, 44]},
  {date: "2016-03-10", numbers: [26, 28, 44, 46, 3, 45]},
  {date: "2016-03-13", numbers: [3, 21, 45, 19, 4, 29]},
  {date: "2016-03-17", numbers: [40, 13, 45, 9, 43, 41]},
  {date: "2016-03-20", numbers: [4, 5, 30, 23, 36, 1]},
  {date: "2016-03-24", numbers: [15, 21, 24, 47, 16, 18]},
  {date: "2016-03-27", numbers: [2, 3, 24, 40, 6, 43]},
  {date: "2016-03-31", numbers: [21, 11, 44, 43, 32, 5]},
  {date: "2016-04-03", numbers: [36, 6, 34, 13, 47, 49]},
  {date: "2016-04-07", numbers: [20, 26, 39, 25, 14, 21]},
  {date: "2016-04-10", numbers: [35, 42, 17, 41, 28, 33]},
  {date: "2016-04-14", numbers: [9, 44, 5, 16, 31, 10]},
  {date: "2016-04-17", numbers: [9, 15, 12, 27, 44, 46]},
  {date: "2016-04-21", numbers: [18, 47, 5, 14, 46, 6]},
  {date: "2016-04-24", numbers: [6, 15, 42, 43, 45, 32]},
  {date: "2016-05-01_0", numbers: [16, 40, 9, 36, 37, 43]},
  {date: "2016-05-01_1", numbers: [22, 23, 11, 39, 42, 8]},
  {date: "2016-05-05", numbers: [39, 5, 19, 13, 15, 32]},
  {date: "2016-05-08", numbers: [48, 17, 34, 25, 14, 5]},
  {date: "2016-05-12", numbers: [3, 37, 6, 22, 2, 10]},
  {date: "2016-05-15", numbers: [24, 4, 42, 25, 37, 20]},
  {date: "2016-05-19", numbers: [24, 7, 34, 2, 9, 37]},
  {date: "2016-05-22", numbers: [30, 13, 1, 42, 34, 19]},
  {date: "2016-05-26", numbers: [1, 43, 21, 45, 26, 20]},
  {date: "2016-05-29", numbers: [11, 48, 3, 19, 10, 5]},
  {date: "2016-06-02", numbers: [13, 27, 37, 8, 23, 12]},
  {date: "2016-06-05", numbers: [39, 7, 4, 13, 36, 22]},
  {date: "2016-06-09", numbers: [30, 42, 44, 18, 10, 31]},
  {date: "2016-06-12", numbers: [27, 20, 25, 46, 22, 11]},
  {date: "2016-06-16", numbers: [30, 16, 4, 9, 13, 2]},
  {date: "2016-06-19", numbers: [25, 35, 30, 4, 11, 14]},
  {date: "2016-06-23", numbers: [24, 7, 46, 35, 3, 27]},
  {date: "2016-06-26", numbers: [24, 43, 1, 25, 46, 22]},
  {date: "2016-06-30", numbers: [1, 27, 34, 49, 14, 6]},
  {date: "2016-07-03", numbers: [42, 25, 38, 22, 27, 15]},
  {date: "2016-07-07", numbers: [15, 31, 26, 4, 42, 17]},
  {date: "2016-07-10", numbers: [9, 38, 45, 21, 20, 5]},
  {date: "2016-07-14", numbers: [22, 31, 34, 47, 10, 49]},
  {date: "2016-07-17", numbers: [12, 26, 9, 45, 4, 47]},
  {date: "2016-07-21", numbers: [2, 41, 42, 6, 39, 12]},
  {date: "2016-07-24", numbers: [24, 16, 28, 21, 6, 33]},
  {date: "2016-07-28", numbers: [42, 44, 10, 3, 33, 27]},
  {date: "2016-07-31", numbers: [38, 32, 31, 49, 3, 4]},
  {date: "2016-08-04", numbers: [16, 42, 2, 6, 46, 30]},
  {date: "2016-08-07", numbers: [7, 5, 36, 41, 4, 32]},
  {date: "2016-08-11", numbers: [14, 4, 19, 49, 34, 38]},
  {date: "2016-08-14", numbers: [47, 3, 49, 6, 7, 24]},
  {date: "2016-08-18", numbers: [6, 44, 1, 4, 19, 8]},
  {date: "2016-08-21", numbers: [38, 17, 5, 11, 36, 13]},
  {date: "2016-08-25", numbers: [6, 11, 25, 9, 12, 1]},
  {date: "2016-08-28", numbers: [48, 6, 4, 30, 13, 26]},
  {date: "2016-09-01", numbers: [19, 26, 45, 25, 22, 3]},
  {date: "2016-09-04", numbers: [41, 13, 21, 43, 45, 28]},
  {date: "2016-09-08", numbers: [40, 39, 14, 46, 7, 41]},
  {date: "2016-09-11", numbers: [34, 21, 25, 14, 46, 43]},
  {date: "2016-09-15", numbers: [22, 18, 5, 28, 29, 15]},
  {date: "2016-09-18_0", numbers: [18, 19, 35, 23, 45, 13]},
  {date: "2016-09-18_1", numbers: [22, 47, 15, 14, 41, 2]},
  {date: "2016-09-22", numbers: [33, 25, 30, 17, 13, 31]},
  {date: "2016-09-25", numbers: [11, 25, 43, 42, 30, 23]},
  {date: "2016-09-29", numbers: [32, 43, 40, 44, 2, 26]},
  {date: "2016-10-02", numbers: [27, 23, 2, 44, 48, 38]},
  {date: "2016-10-06", numbers: [21, 10, 40, 23, 3, 38]},
  {date: "2016-10-09", numbers: [9, 10, 27, 5, 38, 33]},
  {date: "2016-10-13", numbers: [16, 7, 26, 25, 31, 13]},
  {date: "2016-10-16", numbers: [19, 42, 22, 18, 43, 20]},
  {date: "2016-10-20", numbers: [11, 36, 7, 31, 19, 12]},
  {date: "2016-10-23", numbers: [12, 31, 46, 17, 6, 5]},
  {date: "2016-10-27", numbers: [6, 35, 3, 1, 43, 42]},
  {date: "2016-10-30", numbers: [16, 37, 7, 33, 28, 36]},
  {date: "2016-11-03", numbers: [7, 19, 22, 6, 5, 45]},
  {date: "2016-11-06", numbers: [15, 11, 41, 14, 9, 42]},
  {date: "2016-11-10", numbers: [35, 44, 17, 10, 22, 18]},
  {date: "2016-11-13", numbers: [21, 35, 46, 20, 5, 31]},
  {date: "2016-11-17", numbers: [17, 42, 39, 30, 36, 4]},
  {date: "2016-11-20", numbers: [2, 42, 1, 44, 19, 8]},
  {date: "2016-11-24", numbers: [17, 15, 19, 11, 7, 9]},
  {date: "2016-11-27", numbers: [29, 32, 43, 30, 21, 4]},
  {date: "2016-12-04", numbers: [34, 23, 1, 18, 5, 11]},
  {date: "2016-12-08", numbers: [38, 9, 24, 10, 7, 4]},
  {date: "2016-12-11", numbers: [36, 18, 41, 24, 23, 10]},
  {date: "2016-12-15", numbers: [49, 4, 44, 37, 7, 31]},
  {date: "2016-12-18", numbers: [17, 3, 38, 44, 20, 40]},
  {date: "2016-12-22", numbers: [4, 18, 32, 21, 17, 34]},
  {date: "2016-12-31", numbers: [23, 22, 43, 8, 9, 27]},
  {date: "2017-01-05", numbers: [25, 49, 42, 29, 5, 24]},
  {date: "2017-01-08", numbers: [16, 5, 18, 28, 43, 30]},
  {date: "2017-01-12", numbers: [16, 30, 1, 23, 20, 8]},
  {date: "2017-01-15", numbers: [7, 30, 16, 39, 23, 5]},
  {date: "2017-01-19", numbers: [23, 45, 12, 25, 2, 10]},
  {date: "2017-01-22", numbers: [24, 16, 38, 27, 11, 3]},
  {date: "2017-01-26", numbers: [33, 27, 2, 40, 42, 20]},
  {date: "2017-01-29", numbers: [38, 27, 49, 17, 13, 44]},
  {date: "2017-02-02", numbers: [40, 6, 27, 5, 25, 49]},
  {date: "2017-02-05", numbers: [25, 10, 34, 15, 36, 39]},
  {date: "2017-02-09", numbers: [20, 37, 8, 31, 27, 9]},
  {date: "2017-02-12", numbers: [7, 5, 8, 25, 37, 6]},
  {date: "2017-02-16", numbers: [22, 17, 4, 42, 30, 3]},
  {date: "2017-02-19", numbers: [37, 30, 27, 38, 17, 45]},
  {date: "2017-02-23", numbers: [18, 23, 36, 7, 47, 8]},
  {date: "2017-03-02", numbers: [24, 38, 48, 11, 12, 29]},
  {date: "2017-03-05_0", numbers: [43, 14, 49, 23, 3, 6]},
  {date: "2017-03-05_1", numbers: [30, 1, 16, 9, 41, 34]},
  {date: "2017-03-09", numbers: [29, 43, 33, 10, 27, 36]},
  {date: "2017-03-12", numbers: [20, 39, 3, 5, 49, 41]},
  {date: "2017-03-16", numbers: [48, 30, 46, 13, 21, 29]},
  {date: "2017-03-19", numbers: [40, 45, 38, 8, 11, 34]},
  {date: "2017-03-23", numbers: [11, 35, 39, 4, 2, 1]},
  {date: "2017-03-26", numbers: [1, 29, 41, 39, 18, 46]},
  {date: "2017-03-30", numbers: [23, 31, 20, 19, 38, 7]},
  {date: "2017-04-02", numbers: [48, 21, 12, 9, 5, 46]},
  {date: "2017-04-06", numbers: [22, 20, 2, 36, 1, 8]},
  {date: "2017-04-09", numbers: [41, 32, 21, 5, 4, 16]},
  {date: "2017-04-16_0", numbers: [8, 30, 37, 11, 21, 45]},
  {date: "2017-04-06_1", numbers: [23, 42, 21, 30, 4, 12]},
  {date: "2017-04-20", numbers: [1, 21, 18, 6, 31, 3]},
  {date: "2017-04-23", numbers: [13, 45, 42, 14, 48, 41]},
  {date: "2017-04-27", numbers: [49, 2, 19, 44, 9, 35]},
  {date: "2017-04-30", numbers: [5, 40, 8, 12, 38, 16]},
  {date: "2017-05-04", numbers: [13, 25, 22, 15, 34, 11]},
  {date: "2017-05-07", numbers: [18, 13, 21, 4, 16, 11]},
  {date: "2017-05-11", numbers: [1, 32, 34, 49, 24, 17]},
  {date: "2017-05-14", numbers: [1, 38, 25, 2, 11, 6]},
  {date: "2017-05-18", numbers: [47, 13, 6, 3, 32, 17]},
  {date: "2017-05-21", numbers: [17, 32, 39, 48, 43, 41]},
  {date: "2017-05-25", numbers: [4, 20, 15, 2, 10, 19]},
  {date: "2017-05-28", numbers: [11, 13, 2, 43, 12, 39]},
  {date: "2017-06-01", numbers: [48, 30, 19, 38, 36, 29]},
  {date: "2017-06-04", numbers: [39, 19, 14, 12, 26, 16]},
  {date: "2017-06-08", numbers: [7, 6, 16, 26, 18, 9]},
  {date: "2017-06-11", numbers: [8, 49, 38, 17, 33, 28]},
  {date: "2017-06-15", numbers: [20, 15, 4, 39, 46, 3]},
  {date: "2017-06-18", numbers: [38, 3, 48, 30, 1, 12]},
  {date: "2017-06-22", numbers: [2, 40, 22, 44, 42, 30]},
  {date: "2017-06-25_0", numbers: [9, 28, 12, 46, 8, 19]},
  {date: "2017-06-25_1", numbers: [13, 47, 17, 44, 27, 6]},
  {date: "2017-06-29", numbers: [33, 31, 28, 4, 5, 26]},
  {date: "2017-07-02", numbers: [11, 32, 28, 37, 36, 48]},
  {date: "2017-07-06", numbers: [15, 17, 21, 49, 30, 25]},
  {date: "2017-07-09", numbers: [26, 30, 8, 24, 35, 23]},
  {date: "2017-07-13", numbers: [49, 4, 26, 32, 27, 43]},
  {date: "2017-07-16", numbers: [16, 15, 5, 22, 7, 40]},
  {date: "2017-07-20", numbers: [7, 14, 44, 27, 45, 4]},
  {date: "2017-07-23", numbers: [4, 38, 39, 11, 15, 36]},
  {date: "2017-07-27", numbers: [47, 16, 11, 38, 10, 26]},
  {date: "2017-07-30_0", numbers: [25, 48, 28, 38, 1, 24]},
  {date: "2017-07-30_1", numbers: [41, 20, 7, 5, 9, 3]},
  {date: "2017-08-03", numbers: [14, 16, 44, 2, 27, 9]},
  {date: "2017-08-06", numbers: [25, 7, 9, 37, 43, 29]},
  {date: "2017-08-10", numbers: [16, 14, 7, 47, 21, 27]},
  {date: "2017-08-13", numbers: [38, 3, 28, 31, 32, 18]},
  {date: "2017-08-17", numbers: [48, 27, 29, 23, 8, 20]},
  {date: "2017-08-20", numbers: [48, 46, 5, 30, 19, 32]},
  {date: "2017-08-24", numbers: [26, 16, 37, 36, 13, 22]},
  {date: "2017-08-27", numbers: [48, 17, 43, 2, 13, 15]},
  {date: "2017-08-31", numbers: [18, 11, 24, 39, 12, 34]},
  {date: "2017-09-03", numbers: [23, 24, 8, 45, 40, 35]},
  {date: "2017-09-07", numbers: [15, 36, 20, 24, 2, 6]},
  {date: "2017-09-10", numbers: [20, 25, 45, 48, 37, 21]},
  {date: "2017-09-17_0", numbers: [21, 30, 39, 46, 3, 9]},
  {date: "2017-09-17_1", numbers: [5, 14, 10, 23, 26, 40]},
  {date: "2017-09-21", numbers: [4, 32, 36, 8, 1, 24]},
  {date: "2017-09-24", numbers: [32, 5, 4, 49, 44, 12]},
  {date: "2017-09-28", numbers: [3, 6, 37, 7, 48, 28]},
  {date: "2017-10-01", numbers: [21, 38, 7, 6, 49, 13]},
  {date: "2017-10-05", numbers: [30, 6, 17, 41, 4, 28]},
  {date: "2017-10-08", numbers: [5, 15, 28, 41, 32, 7]},
  {date: "2017-10-12", numbers: [44, 35, 36, 19, 21, 42]},
  {date: "2017-10-15_0", numbers: [46, 22, 47, 43, 27, 28]},
  {date: "2017-10-15_1", numbers: [44, 19, 25, 6, 9, 10]},
  {date: "2017-10-19", numbers: [9, 32, 11, 19, 29, 17]},
  {date: "2017-10-22", numbers: [2, 28, 41, 27, 26, 3]},
  {date: "2017-10-26", numbers: [10, 21, 24, 22, 37, 23]},
  {date: "2017-10-29", numbers: [18, 37, 27, 10, 44, 5]},
  {date: "2017-11-02", numbers: [49, 21, 24, 28, 36, 16]},
  {date: "2017-11-05", numbers: [13, 3, 18, 12, 21, 45]},
  {date: "2017-11-09", numbers: [23, 47, 28, 7, 39, 9]},
  {date: "2017-11-12", numbers: [19, 12, 32, 7, 33, 17]},
  {date: "2017-11-16", numbers: [21, 5, 44, 48, 11, 49]},
  {date: "2017-11-19", numbers: [8, 15, 30, 42, 39, 35]},
  {date: "2017-11-23", numbers: [30, 10, 11, 45, 15, 37]},
  {date: "2017-11-26_0", numbers: [37, 11, 35, 27, 12, 4]},
  {date: "2017-11-26_1", numbers: [23, 30, 44, 13, 2, 15]},
  {date: "2017-11-30", numbers: [17, 15, 41, 39, 45, 40]},
  {date: "2017-12-03", numbers: [42, 20, 5, 4, 23, 38]},
  {date: "2017-12-07", numbers: [32, 14, 37, 40, 47, 49]},
  {date: "2017-12-10", numbers: [38, 49, 10, 1, 46, 22]},
  {date: "2017-12-14", numbers: [40, 2, 45, 13, 11, 5]},
  {date: "2017-12-17", numbers: [34, 30, 47, 46, 45, 26]},
  {date: "2017-12-21", numbers: [19, 46, 30, 33, 36, 17]},
  {date: "2017-12-24", numbers: [18, 33, 17, 12, 44, 28]},
  {date: "2017-12-31_0", numbers: [43, 36, 2, 42, 32, 28]},
  {date: "2017-12-31_1", numbers: [5, 44, 12, 9, 29, 16]},
  {date: "2018-01-04", numbers: [8, 27, 47, 2, 25, 3]},
  {date: "2018-01-07", numbers: [4, 12, 5, 19, 33, 2]},
  {date: "2018-01-11", numbers: [35, 20, 10, 31, 3, 32]},
  {date: "2018-01-14", numbers: [16, 36, 34, 30, 43, 27]},
  {date: "2018-01-18", numbers: [1, 49, 21, 34, 2, 12]},
  {date: "2018-01-21", numbers: [45, 35, 17, 33, 23, 11]},
  {date: "2018-01-25", numbers: [48, 10, 17, 31, 25, 29]},
  {date: "2018-01-28", numbers: [18, 34, 5, 28, 33, 2]},
  {date: "2018-02-01", numbers: [37, 34, 27, 46, 4, 28]},
  {date: "2018-02-04", numbers: [37, 17, 26, 39, 35, 4]},
  {date: "2018-02-08", numbers: [3, 29, 23, 40, 39, 9]},
  {date: "2018-02-11", numbers: [5, 14, 30, 34, 22, 18]},
  {date: "2018-02-15", numbers: [30, 33, 5, 2, 34, 19]},
  {date: "2018-02-18", numbers: [21, 12, 42, 8, 37, 2]},
  {date: "2018-02-22", numbers: [47, 8, 35, 18, 26, 2]},
  {date: "2018-02-25", numbers: [3, 1, 47, 19, 28, 34]},
  {date: "2018-03-01", numbers: [6, 11, 39, 43, 33, 9]},
  {date: "2018-03-04_0", numbers: [17, 40, 3, 31, 19, 41]},
  {date: "2018-03-04_1", numbers: [20, 40, 19, 32, 39, 29]},
  {date: "2018-03-08", numbers: [12, 4, 24, 42, 31, 2]},
  {date: "2018-03-11", numbers: [27, 43, 48, 29, 35, 1]},
  {date: "2018-03-15", numbers: [34, 19, 3, 20, 10, 17]},
  {date: "2018-03-18", numbers: [9, 37, 41, 33, 47, 25]},
  {date: "2018-03-22", numbers: [4, 15, 48, 2, 23, 20]},
  {date: "2018-03-25", numbers: [2, 39, 12, 36, 34, 20]},
  {date: "2018-03-29", numbers: [23, 35, 31, 5, 6, 43]},
  {date: "2018-04-01", numbers: [12, 23, 20, 11, 42, 18]},
  {date: "2018-04-08_0", numbers: [31, 9, 18, 29, 32, 21]},
  {date: "2018-04-08_1", numbers: [45, 49, 9, 37, 7, 31]},
  {date: "2018-04-12", numbers: [2, 24, 43, 49, 16, 40]},
  {date: "2018-04-15", numbers: [15, 9, 31, 21, 13, 4]},
  {date: "2018-04-19", numbers: [4, 21, 22, 27, 24, 37]},
  {date: "2018-04-22", numbers: [14, 18, 39, 21, 45, 29]},
  {date: "2018-04-26", numbers: [14, 18, 19, 24, 33, 6]},
  {date: "2018-04-29", numbers: [30, 49, 14, 46, 37, 32]},
  {date: "2018-05-03", numbers: [29, 37, 44, 3, 9, 6]},
  {date: "2018-05-06", numbers: [35, 16, 25, 9, 23, 49]},
  {date: "2018-05-10", numbers: [1, 20, 39, 21, 43, 44]},
  {date: "2018-05-13", numbers: [33, 9, 17, 49, 11, 34]},
  {date: "2018-05-17", numbers: [24, 34, 17, 8, 5, 20]},
  {date: "2018-05-20", numbers: [12, 16, 33, 45, 3, 10]},
  {date: "2018-05-24", numbers: [1, 14, 38, 47, 33, 7]},
  {date: "2018-05-27", numbers: [2, 27, 14, 45, 7, 46]},
  {date: "2018-05-31", numbers: [24, 33, 19, 28, 35, 4]},
  {date: "2018-06-03", numbers: [1, 11, 47, 22, 36, 46]},
  {date: "2018-06-07", numbers: [43, 4, 38, 25, 16, 46]},
  {date: "2018-06-10", numbers: [47, 21, 25, 22, 40, 42]},
  {date: "2018-06-14", numbers: [6, 23, 3, 35, 32, 44]},
  {date: "2018-06-17", numbers: [3, 19, 20, 23, 17, 37]},
  {date: "2018-06-21", numbers: [30, 31, 19, 49, 17, 34]},
  {date: "2018-06-24", numbers: [24, 49, 42, 3, 21, 41]},
  {date: "2018-06-28", numbers: [49, 23, 25, 24, 41, 4]},
  {date: "2018-07-01", numbers: [19, 43, 20, 18, 5, 11]},
  {date: "2018-07-05", numbers: [28, 5, 44, 10, 26, 38]},
  {date: "2018-07-08", numbers: [25, 36, 31, 8, 7, 40]},
  {date: "2018-07-12", numbers: [2, 42, 29, 39, 40, 44]},
  {date: "2018-07-15", numbers: [3, 29, 46, 32, 23, 27]},
  {date: "2018-07-19", numbers: [23, 49, 5, 2, 41, 16]},
  {date: "2018-07-22", numbers: [4, 48, 39, 44, 24, 9]},
  {date: "2018-07-26", numbers: [6, 12, 44, 27, 4, 30]},
  {date: "2018-07-29", numbers: [38, 9, 14, 45, 31, 23]},
  {date: "2018-08-02", numbers: [21, 9, 8, 22, 40, 27]},
  {date: "2018-08-05", numbers: [2, 31, 43, 24, 40, 19]},
  {date: "2018-08-09", numbers: [41, 42, 25, 28, 38, 30]},
  {date: "2018-08-12", numbers: [3, 23, 16, 12, 10, 45]},
  {date: "2018-08-16", numbers: [5, 16, 37, 32, 3, 10]},
  {date: "2018-08-19", numbers: [31, 15, 24, 18, 33, 35]},
  {date: "2018-08-23", numbers: [37, 14, 47, 28, 39, 4]},
  {date: "2018-08-26", numbers: [19, 43, 26, 27, 14, 38]},
  {date: "2018-08-30", numbers: [29, 27, 12, 25, 7, 39]},
  {date: "2018-09-02", numbers: [45, 43, 28, 14, 18, 4]},
  {date: "2018-09-06", numbers: [48, 26, 13, 28, 7, 22]},
  {date: "2018-09-09", numbers: [44, 16, 27, 48, 9, 19]},
  {date: "2018-09-13", numbers: [42, 20, 3, 34, 31, 13]}
];
