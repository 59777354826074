import {Component, OnInit, ViewChild} from '@angular/core';
import {LotoHistoryItem} from '../LotoHistoryItem';
import {HistoryService} from '../history.service';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-history-numbers',
  templateUrl: './history-numbers.component.html',
  styleUrls: ['./history-numbers.component.css']
})
export class HistoryNumbersComponent implements OnInit {

  history: LotoHistoryItem[];
  displayedColumns: string[] = ['select', 'date', 'numbers'];
  dataSource: MatTableDataSource<LotoHistoryItem>;
  selection = new SelectionModel<Element>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private historyService: HistoryService) { }

  ngOnInit() {
    this.historyService.getHistoryFromServer()
      .subscribe(historyItems => {
        this.history =  historyItems;
        this.dataSource = new MatTableDataSource(historyItems);
        this.dataSource.paginator = this.paginator;
      });
  }

  delete(item: LotoHistoryItem): void {
    this.historyService.deleteLotoItem(item)
        .subscribe(historyItem => {
          const index: number = this.history.indexOf(item);
          if (index !== -1) {
            this.history.splice(index, 1);
            this.dataSource._updateChangeSubscription();
          }
        });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  removeSelectedRows(): void {
    this.selection.selected.forEach(item => {
      const deleteIem = this.findItem(item['date']);
      this.delete(deleteIem);
      this.selection = new SelectionModel<Element>(true, []);
    });
  }

  findItem(date: string): LotoHistoryItem{
    for(let i = 0; i < this.history.length; i++){
      if(this.history[i].date == date){
        return this.history[i];
      }
    }
    return null;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected() ?
  //   this.selection.clear() :
  //   this.dataSource.data.forEach(row => this.selection.select(row));
  // }
}
