import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {LotoComponent} from './loto/loto.component';
import {CvComponent} from './cv/cv.component';
import {ManageNumbersComponent} from './loto/manage-numbers/manage-numbers.component';
import {HistoryNumbersComponent} from './loto/history-numbers/history-numbers.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'cv', component: CvComponent },
  { path: 'loto', component: LotoComponent },
  { path: 'loto/manage', component: ManageNumbersComponent },
  { path: 'loto/history', component: HistoryNumbersComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
