import {Injectable} from '@angular/core';
import {LotoHistoryItem} from './LotoHistoryItem';
import {Observable, of} from 'rxjs';
import {LOTO_DATA} from './LotoData';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable({
                providedIn : 'root'
            })
export class HistoryService {
    private lotoApiUrl = 'http://timurlea.ro/php/lotoApi.php';
    private lotoSaveApiUrl = 'http://timurlea.ro/php/lotoSaveApi.php';
    private lotoDeleteApiUrl = 'http://timurlea.ro/php/lotoDeleteApi.php';

    constructor(private http: HttpClient) {
    }

    getHistory(): Observable<LotoHistoryItem[]> {
        return of(LOTO_DATA);
    }

    getHistoryFromServer(): Observable<LotoHistoryItem[]> {
        return this.http.get<LotoHistoryItem[]>(this.lotoApiUrl)
                   .pipe(
                       tap(_ => this.log('fetched data')),
                       catchError(this.handleError('getHistoryFromServer', []))
                   );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        console.log(message);
    }

    addLotoItem (lotoItem: LotoHistoryItem): Observable<any[] | LotoHistoryItem> {
        return this.http.post<any[] | LotoHistoryItem>(this.lotoSaveApiUrl, {date: lotoItem.date, numbers: lotoItem.numbers})
                   .pipe(
                       tap(_ => this.log('post data')),
                       catchError(this.handleError('addLotoItem', []))
                   );
    }

    deleteLotoItem (lotoItem: LotoHistoryItem): Observable<any[] | LotoHistoryItem> {
        return this.http.post<any[] | LotoHistoryItem>(this.lotoDeleteApiUrl, {date: lotoItem.date, numbers: lotoItem.numbers})
                   .pipe(
                       tap(_ => this.log('delete data')),
                       catchError(this.handleError('deleteLotoItem', []))
                   );
    }
}
