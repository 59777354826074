import { Component, OnInit } from '@angular/core';
import {HistoryService} from '../history.service';
import {MatDatepickerInputEvent} from '@angular/material';
import {LotoHistoryItem} from '../LotoHistoryItem';

@Component({
  selector: 'app-manage-numbers',
  templateUrl: './manage-numbers.component.html',
  styleUrls: ['./manage-numbers.component.css']
})
export class ManageNumbersComponent implements OnInit {
  curentDate: Date;
  numbers: string[] = [];
  selectedNumbers: number[] = [];
  messages: string[] = [];
  special0 = false;
  special1 = false;

  constructor(private historyService: HistoryService) { }

  ngOnInit() {
    for (let i = 1; i <= 49 ; i++) {
      this.numbers.push(i.toString());
    }
  }

  onDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.curentDate = event.value;
  }

  onNumbersChange(event, item: number): void {
      if (event['checked']) {
        this.selectedNumbers.push(item);
      } else {
        const index: number = this.selectedNumbers.indexOf(item);
        if (index !== -1) {
          this.selectedNumbers.splice(index, 1);
        }
      }
  }

  onSpecial0Change(event): void {
    this.special0 = event['checked'];
  }
  onSpecial1Change(event): void {
    this.special1 = event['checked'];
  }

  addToHistory(numbers: string): void {
    if (!this.selectedNumbers || this.selectedNumbers.length < 6 || !this.curentDate) { return; }

    const lotoItem: LotoHistoryItem = new LotoHistoryItem();
    lotoItem.date = this.getFormatedDate(this.curentDate);
    if (this.special0 && !this.special1) {
      lotoItem.date += '_0';
    } else if ((this.special0 && this.special1) || (this.special1)) {
      lotoItem.date += '_1';
    }
    lotoItem.numbers = this.selectedNumbers;
    this.historyService.addLotoItem(lotoItem)
        .subscribe(historyItem => {
          console.log('added ' + historyItem['date'] + ' date');
          this.messages.push(historyItem['date'] + ' -> ' + historyItem['numbers'] + ' added to database.');
        });
  }

  getFormatedDate(d: Date): string {
    const year = d.getFullYear().toString();
    let month;
    if (d.getMonth() + 1 < 10) {
      month = '0' + (d.getMonth() + 1);
    } else {
      month = d.getMonth() + 1;
    }

    let day;
    if (d.getDate() < 10) {
      day = '0' + d.getDate();
    } else {
      day = d.getDate();
    }
    return year + '-' + month + '-' + day;
  }

}
