import { Injectable } from '@angular/core';
import {HistoryService} from './history.service';
import {LotoNumber} from './loto_number';
import {LotoHistoryItem} from './LotoHistoryItem';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenerateNumbersService {
  data: LotoHistoryItem[];
  numbers: Array<LotoNumber>;
  validatedNumbers: number[];
  constructor(private historyService: HistoryService) { }

  getNumbers():Observable<number[]>{
    this.historyService.getHistory()
        .subscribe(history => this.data = history);

    this.init();
    this.spreadData();
    this.computeInterval();
    let predictions:Object[] = this.getPredictions();
    this.getSix(predictions);
    return of(this.validatedNumbers);
  }

  private init(): void {
    this.numbers = new Array<LotoNumber>();
    for (var i = 0; i < 49; i++) {
      let number: LotoNumber = new LotoNumber((i+1).toString());
      this.numbers.push(number);

    }
  }

  private spreadData():void{
    this.data.forEach(function (value:LotoHistoryItem, key: number) {
      for (let i = 0; i < this.numbers.length; i++) {
        let number = this.numbers[i];
        if(value.numbers.indexOf(number.id)!=-1){
          number.addValue(key);
        }
      }
    }, this);
  }

  private getPredictions():Object[]{

    let predictions: Object[] = [];

    for (let i = 0; i < this.numbers.length; i++) {
      var number:LotoNumber = this.numbers[i];
      predictions.push({id: number.id, willappear: number.willAppearIn});
    }

    //sort predictions
    for (let i = 0; i < predictions.length-1; i++) {
      for (let j = i+1; j < predictions.length; j++) {
        if(predictions[i]["willappear"] > predictions[j]["willappear"]){
          let aux = predictions[i];
          predictions[i] = predictions[j];
          predictions[j] = aux;
        }
      }
    }
    return predictions;
  }

  private computeInterval():void{
    for (let i = 0; i < this.numbers.length; i++) {
      this.numbers[i].computeInterval();
    }
  }

  private getSix(predictions:Object[]):void{
    this.validatedNumbers = [];
    for (let i = 0; i < predictions.length; i++) {
      if(i < 6){
        this.validatedNumbers.push(predictions[i]["id"]);
      }
    }
  }
}
